<template>
  <div class="profile">
    <UserImage
      :gender="userInfo.user_sex"
      :image="userInfo.user_profile_image"
      @click="onImage(userInfo.user_profile_image)"/>
  </div>
  <div class="info">
    <div class="text">
      <p>{{ $t('profile.profile_25') }}</p>
      <h1>
        <img  style="width: 30px; height: 24px; margin-top: -5px;" :src="require('../assets/flag/' + userInfo.user_country_code + '.png')">
        {{ userInfo.user_nickname }}
        <b :class="[{male: userInfo.user_sex == '0', female: userInfo.user_sex == '1'}]">
          ({{
            (userInfo.user_sex == '0' ? $t('common.male_short') : $t('common.female_short')) + userInfo.user_age
          }})
        </b>
      </h1>
      <!-- TODO - photo count  -->
      <span>{{ $t('photo.gift') }} : <b>{{ currency(userStats.gift_count) }}</b></span>
      <span>{{ $t('profile.recommend') }} : <b>{{ currency(userInfo.user_like_cnt) }}</b></span>
      <a href="javascript:" @click="onReport">
        <img src="../assets/img/ico_report.png">
      </a>
    </div>
    <div class="btn">
      <a href="javascript:" @click="onJjim">
        <img src="../assets/img/ico_pro01.png">{{ $t('profile.dibs') }}
      </a>
      <a href="javascript:" @click="onLike">
        <img src="../assets/img/ico_pro02.png">{{ $t('profile.recommend') }}
      </a>
      <a href="javascript:" @click="onAlbum">
        <img src="../assets/img/ico_pro03.png">{{ $t('profile.album') }}
      </a>
      <a href="javascript:" @click="onMessage">
        <img src="../assets/img/ico_pro04.png">{{ $t('profile.message') }}
      </a>
    </div>
    <div class="avg">
      <ul>
        <li>
          <img src="../assets/img/ico_avg01.png">
          {{ $t('recommend.average_video_time') }} <b>{{ userStats.video_chat_time }}{{ $t('common.second') }}</b>
          <table class="time">
            <tr>
              <td>{{ $t('recommend.video_time_below_15s') }}</td>
              <td>
                <ProgressView
                  :value="Number(userStats.video_chat_counts_by_duration.below_15secs)"
                  :max-value="maxValue"/>
              </td>
              <td>{{ currency(userStats.video_chat_counts_by_duration.below_15secs) }}</td>
            </tr>
            <tr>
              <td>{{ $t('recommend.video_time_below_1min') }}</td>
              <td>
                <ProgressView
                  :value="Number(userStats.video_chat_counts_by_duration.below_1min)"
                  :max-value="maxValue"/>
              </td>
              <td>{{ currency(userStats.video_chat_counts_by_duration.below_1min) }}</td>
            </tr>
            <tr>
              <td>{{ $t('recommend.video_time_below_3min') }}</td>
              <td>
                <ProgressView
                  :value="Number(userStats.video_chat_counts_by_duration.below_3mins)"
                  :max-value="maxValue"/>
              </td>
              <td>{{ currency(userStats.video_chat_counts_by_duration.below_3mins) }}</td>
            </tr>
            <tr>
              <td>{{ $t('recommend.video_time_above_3min') }}</td>
              <td>
                <ProgressView
                  :value="Number(userStats.video_chat_counts_by_duration.above_3mins)"
                  :max-value="maxValue"/>
              </td>
              <td>{{ currency(userStats.video_chat_counts_by_duration.above_3mins) }}</td>
            </tr>
          </table>
        </li>
        <li>
          <img src="../assets/img/ico_avg03.png">
          {{ $t('recommend.average_review_point') }} <b>{{ Number(userStats.review_value).toFixed(1) }}</b>
          <table class="rate">
            <tr>
              <td>{{ $t('profile.favorite') }}</td>
              <td>
                <StarView :value="userStats.review_values_by_paramter[0].review_value"/>
              </td>
              <td>{{ currency(userStats.review_values_by_paramter[0].review_count) }}</td>
            </tr>
            <tr>
              <td>{{ $t('profile.belive') }}</td>
              <td>
                <StarView :value="userStats.review_values_by_paramter[1].review_value"/>
              </td>
              <td>{{ currency(userStats.review_values_by_paramter[1].review_count) }}</td>
            </tr>
            <tr>
              <td>{{ $t('profile.manner') }}</td>
              <td>
                <StarView :value="userStats.review_values_by_paramter[2].review_value"/>
              </td>
              <td>{{ currency(userStats.review_values_by_paramter[2].review_count) }}</td>
            </tr>
            <tr>
              <td>{{ $t('profile.beauty') }}</td>
              <td>
                <StarView :value="userStats.review_values_by_paramter[3].review_value"/>
              </td>
              <td>{{ currency(userStats.review_values_by_paramter[3].review_count) }}</td>
            </tr>
          </table>
        </li>
      </ul>
    </div>
    <div class="gobtn">
      <a href="javascript:" class="voice" @click="onVoice">
        <img src="../assets/img/ico_voice.png">{{ $t('profile.voice_chat') }}
      </a>
      <a href="javascript:" class="video" @click="onVideo">
        <img src="../assets/img/ico_video.png">{{ $t('profile.video_chat') }}
      </a>
    </div>
  </div>
  <video-request
    :show="myInfo != null && showVideoRequest"
    :user="userInfo"
    @action="onVideoCallback"
    @close="showVideoRequest = false"/>
</template>
<script>
import "./UserProfile.scss";
import UserImage from "@/components/UserImage";
import { loadData, requestPost, showToast } from "@/lib/util";
import ProgressView from "@/components/ProgressView";
import StarView from "@/components/StarView";
import VideoRequest from "./../views/dialog/VideoRequest";
import { EVENT_NAME, OFFLINE_MODE } from "../lib/constant";
import { detectWebcam } from "../lib/util";

export default {
  name: 'UserProfile',
  components: {
    UserImage,
    ProgressView,
    StarView,
    VideoRequest,
  },
  props: {
    user: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
  },
  emits: ['close'],
  data() {
    return {
      userStats: {
        "video_chat_time": 0,
        "total_video_chat_time": 0,
        "review_value": 0,
        "video_chat_counts_by_duration": {
          "below_15secs": 0,
          "below_1min": 0,
          "below_3mins": 0,
          "above_3mins": 0
        },
        "review_values_by_paramter": [
          {
            "review_value": 0,
            "review_count": 0
          },
          {
            "review_value": 0,
            "review_count": 0
          },
          {
            "review_value": 0,
            "review_count": 0
          },
          {
            "review_value": 0,
            "review_count": 0
          }
        ],
        "gift_count": 0,
      },
      showGlobalVideoPoint: false,
      showVideoRequest: false
    }
  },
  computed: {
    userInfo() {
      return this.user;
    },
    myInfo() {
      return this.$store.getters.me;
    },
    settingInfo() {
      return this.$store.getters.setting;
    },
    isGlobal() {
      return [48, 15, 51].includes(this.user.user_class);
    },
    maxValue() {
      let count_array = [
        this.userStats.video_chat_counts_by_duration.below_15secs,
        this.userStats.video_chat_counts_by_duration.below_1min,
        this.userStats.video_chat_counts_by_duration.below_3mins,
        this.userStats.video_chat_counts_by_duration.above_3mins,
      ]
      return Math.max(Math.max.apply(Math, count_array), 1);
    },
  },
  mounted() {
    this.loadStats();
    this.emitter.on(EVENT_NAME.REPORT_SUCCESS, this.onReportSuccess);
  },
  beforeUnmount() {
    this.emitter.off(EVENT_NAME.REPORT_SUCCESS, this.onReportSuccess);
  },
  watch: {
    user(newUser) {
      this.loadStats();
    }
  },
  methods: {
    onMessage: function () {
      if (this.myInfo == null) {      
        this.$emit('close');
        this.$router.replace({
          name: 'Logout'
        });
        return;
      }
      this.$root.showMessageSendDlg(this.userInfo);
    },

    onVoice: function () {
      showToast('Preparing ...');
    },

    onVideo: function () {
      if (this.myInfo == null) {      
        this.$emit('close');
        this.$router.replace({
          name: 'Logout'
        });
        return;
      }

      if (this.userInfo.user_sex == this.myInfo.user_sex) {
        showToast(this.$t('video.video_27'));
        return;
      }

      if (this.userInfo.user_line_busy == 1) {
        showToast(this.$t('video.video_14'));
        return;
      }

      if (this.isGlobal && this.userInfo.user_sex == 0) {
        let visible = loadData('global_video_chat_warning_visible', true);
        if (visible) {
          this.showGlobalVideoPoint = true;
        } else {
          this.showVideoDialog();
        }
      } else {
        this.showVideoDialog();
      }
    },

    onLike: function () {
      if (this.myInfo == null) {      
        this.$emit('close');
        this.$router.replace({
          name: 'Logout'
        });
        return;
      }

      let _app = this;
      requestPost(
        'user/likeUser',
        {
          uid: this.myInfo.uid,
          like_uid: this.userInfo.uid
        },
        response => {
          showToast(_app.$t('message.msg_like_success'));
          _app.userInfo.user_like_cnt = response.like_count;
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },

    onJjim: function () {
      if (this.myInfo == null) {      
        this.$emit('close');
        this.$router.replace({
          name: 'Logout'
        });
        return;
      }

      let _app = this;
      requestPost(
        'jjim/createJjim',
        {
          uid: this.myInfo.uid,
          peer_uid: this.userInfo.uid
        },
        () => {
          showToast(_app.$t('video.video_profile_02', { nickname: _app.userInfo.user_nickname }));
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },

    onAlbum: function () {
      this.$emit('close');
      this.emitter.emit(EVENT_NAME.CLOSE_DIALOGS);
      window.localStorage.setItem('Album.UserInfo', JSON.stringify(this.userInfo));
      this.$router.push('/album/' + this.userInfo.uid);
    },

    onReport: function () {
      if (this.myInfo == null) {      
        this.$emit('close');
        this.$router.replace({
          name: 'Logout'
        });
        return;
      }

      this.$root.showReportDlg(this.userInfo);
    },

    onReportSuccess: function () {
      this.$emit('close');
    },

    onImage: function (url) {
      if (url != '') {
        window.open(url, '_blank');
      }
    },

    onVideoCallback: function (action) {
      this.showVideoRequest = false;

      let { name, data } = action;
      switch (name) {
        case 'charge':
          this.$emit('close');
          this.$root.onCharge();
          break;
        case 'fail':
          this.$emit('close');
          break;
        case 'accept':
          this.$emit('close');
          // go to video chat
          // data => {roomUrl: '', roomId: '', maxTime: '', deviceTime: '', freeChatTime: ''}
          this.$root.go2VideoChat({
            roomId: data.roomUrl,
            roomUrl: data.roomId,
            maxVideoTime: data.maxTime,
            pointVideo: Math.abs(this.settingInfo.point_video_default),
            chattingWaitTime: parseInt(this.settingInfo.chatting_wait_time),
            freeChatTime: data.freeChatTime,
            peerUser: this.userInfo,
            fromUser: this.myInfo,
          });
          break;
      }
    },

    showVideoDialog: function () {
      if (this.showGlobalVideoPoint) {
        this.showGlobalVideoPoint = false;
      }

      if (OFFLINE_MODE) {
        this.showVideoRequest = true;
      } else {
        detectWebcam((hasWebcam) => {
          if (hasWebcam) {
            this.showVideoRequest = true;
          } else {
            showToast(this.$t('video.video_28'));
          }
        });
      }
    },

    loadStats: function () {
      let _app = this;

      requestPost(
        'user/getAverageVideoChatTimesAndReviewValues',
        {
          peer_uid: this.userInfo.uid
        },
        response => {
          _app.userStats = response;
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
  }
}
</script>
