export default {
  app_name: '뷰챗',
  app_error: {
    title: '예기치 않은 오류가 발생하였습니다.',
    msg: '오류정보가 담당팀에 전달되었습니다. 다시 실행해 주세요.',
    network: '네트워크 통신 오류가 발생하였습니다.',
    other_device_login: '다른 장치에서 동일한 번호로 가입되었습니다.',
    image_not_supported: '지원되지 않은 이미지 형식 입니다.',
    upload_file_not_exist: "업로드 파일이 존재하지 않습니다.",
  },
  common: {
    male_short: 'M',
    female_short: 'F',
    male: "남성",
    female: "여성",
    age: "",
    preparing: "준비중...",
    yes: "Yes",
    no: "No",
    ok: "OK",
    cancel: "Cancel",
    point: "포인트",
    cash: "캐시",
    bread: "빵",
    close: "닫기",
    delete: "지우기",
    second: "초",
    send: "보내기",
    create: "생성하기",
    submit: "제출하기",
    like: "좋아요",
    jjim: "찜",
    message: "메시지",
    hits: "조회수",
    next: "다음",
    name: "이름",
    phone_number: "전화번호",
    years: "세",
    support_type: "point_system-error_payment-error_other",
    max: "최대",
    point_alert: "포인트가 충분하지 않습니다!",
    bread_alert: "빵이 부족 합니다!",
    done: "완료",
    top_title: "<b>{nickname}</b>'s 프로필",
    top_subtitle: "다양한 기능과 프로필을 설정하여 ViewChat을 이용 할 수 있습니다.",
  },
  msg: {
    not_implemented_yet: "서비스 준비중 입니다.",
    please_wait: "잠시 기다려 주세요...",
    input_intro: "소개글 선택",
    select_sex: "성별 선택",
    select_age: "나이 선택",
    input_nickname: "닉네임을 넣어 주세요.",
    nickname_too_short: "닉네임은 2자 이하 불가 합니다.",
    nickname_too_long: "닉네임은 5자 이내 여야 합니다.",
    input_message: "메시지 입력",
    sent_message: "메시지를 보냈습니다.",
    software_update: "소프트웨어 업데이트",
    select_country: "국가 선택",
    like_success: "좋아요 성공",
    please_select_photo: "이미지 선택",
    input_photo_content: "스토리 설명글을 작성해주세요.",
    zzalbe_registered: "이미지가 등록 되었습니다!",
    zzalbe_registered_with_point: "{point} 포인트를 받았습니다.",
    zzalbe_success: "이미지는 검수후 게재 됩니다.",
    zzalbe_comment: "코멘트를 입력하세요.",
    block_user: "{nickname} 차단",
    send_msg_disabled: "메세지 전송 실패 하였습니다.",
    video_request_cancel: "{second} 초후 자동으로 취소 됩니다.",
    video_waiting_time_out: "영상 신청 시간이 초과되었습니다. 나중에 다시 시도 하십시오.",
    check_in: "출석체크 하였습니다. 내일 출석체크 하는것도 잊지 마세요.",
    profile_saved: "프로필 정보 저장",
  },
  header_bar: {
    live: '실시간',
    recom: '추천',
    photo: '사진',
    moment: '모멘트',
    chat: '쪽지',
    profile: '프로필',
    follow: '팔로우 관리',
    charge: '충전',
    history: '사용내역',
    guide: '안내',
    point: '포인트:',
    agreement: "약관 및 정책",
    jp_purchase_policy: '특정상거래법',
    usage: '이용약관',
    privacy: '개인정보보호정책',
    ugc: '컨텐츠 계약',
    youth_policy: '청소년 보호정책',
    company_name: 'BC INNOVATION',
    boss: 'CEO : Hyunsuk Park',
    biz: 'Biz No : 227-81-20333',
    southwest: '305-1, 39 Cheongdae-ro 204beon-gil, Sokcho-si, Gangwon-do',
    tel: ' TEL : 1599-1401',
    email_prefix: 'E-Mail : contact',
    email_suffix: 'bcinnovation.co.kr',
    login: '로그인',
    logout: '로그아웃',
    logout_confirm: '정말로 로그아웃 하시겠습니까?',
    top_button: "제일 위로",
    viewchat_point: "뷰챗 포인트",
    view_more: "더보기",
    viewchat_live: "라이브",
  },
  splash: {
    start_with_sns: 'SNS로 시작하기',
    view_chat_login: 'Email 로그인',
    view_chat_signup: '회원가입',
    forgot_password: '비밀번호 찾기',
  },
  reset_password: {
    title: '비밀번호 재설정',
    button_title: '비밀번호 재설정',
    completed: '비밀번호가 재설정 되었습니다.',
  },
  login: {
    title: "뷰챗- 얼굴보며 영상 채팅",
    login_01: "뷰챗에 오신걸 환영합니다.",
    login_02: "즐거운 페이스 챗팅, 즐거운 영상통화, 뷰챗",
    guide: '정확한 ID와 비밀번호를 입력 하여 주세요.',
    input_id: 'ID 입력',
    input_pwd: '비밀번호 입력',
    auto_login: '자동 로그인',
    login: '로그인',
  },
  phone: {
    verify: "확인",
    exit: "나가기",
    str_adult_auth_title: "Title",
    str_adult_auth_text1_1: "Text1-1",
    str_adult_auth_text1_2: "Text1-2",
    str_adult_auth_text3_1: "Text3-1",
    str_adult_auth_text3_2: "Text3-2",
    str_adult_auth_text3_3: "Text3-3",
  },
  age_agreement: {
    title: 'Age agreement',
    text1: 'Are you older than 18?',
    text2: 'Only adults can use this service!',
    reject: 'Reject',
    agree: 'Agree'
  },
  signup: {
    only_10_sec: '10초안에 회원가입하기,',
    sign_up: '가입해 주세요!',
    id_placeholder: 'ID (이메일주소)',
    invalid_id: '잘못된 ID 입니다. (이메일주소)',
    pwd: '비밀번호',
    pwd_confirm: '비밀번호 확인',
    pwd_confirmed: '비밀번호가 확인되었습니다!',
    agree_to_usage: '이용약관 및 개인정보취급방침에 동의',
    select_profile: '프로필 이미지 선택',
    select_photo: '이미지 선택',
    nickname_placeholder: '닉네임을 입력하세요(최대 6자리)',
    yrs: '세',
    pay_attention: '* 가입후 변경이 안되오니 주의해 주세요!',
    start: '시작',
    title: "가입",
    signup: "가입",
    msg_input_nickname: "닉네임을 입력해 주세요!",
    msg_input_gender: "성별을 선택해 주세요!",
    msg_input_motto: "주제를 선택해 주세요!",
    msg_input_age: "나이를 선택해 주세요!",
    gender_warning: "성별은 가입후 변경 불가합니다.",
    msg_nickname_too_short: "닉네임은 2자 이하가 될 수 없습니다.",
    msg_nickname_too_long: "닉네임은 5자를 넘을 수 없습니다.",
    send_cert_key: "인증키 전송",
    verify_cert_key: "인증키 확인",
    msg_input_email: "이메일 주소를 기입해 주세요!",
    msg_input_cert_key: "인증키를 입력해주세요.",
    msg_sent_cert_key: "이메일로 인증키를 보냈습니다. 아래 입력란에 코드를 입력해주세요.",
    cert_key_placeholder: "인증키",
    email_verified: "이메일 인증완료",
    msg_verify_email: "이메일 주소를 기입해 주세요!",
    msg_input_password: "비밀번호를 입력하세요.",
    msg_confirm_password: "비밀번호를 확인해주세요!",
  },
  live: {
    title: "{nickname}님 뷰챗 라이브에 오신것을 환영합니다</b>",
    subtitle: "{message_count}개의 새로운 메세지와 {notification_count}개의 새로운 알림이 있습니다.",
    all_country: "전체국가",
    more_country: "국가 더보기",
    real_time_hot_country: "실시간 인기 국가:",
    ranking: '랭킹순',
    recent_login: '접속순',
    video_chatting: "채팅중",
    more: "더보기",
  },
  recommend: {
    title: "뷰챗 추천회원",
    subtitle: "뷰챗이 추천하는 실시간 영상채팅 회원입니다",
    recommend_today: '오늘',
    recommend_recom: '추천',
    recommend_members: '회원',
    recommend_users: "지금 접속해있는 추천회원입니다.",
    total_star: "전체",
    total_star_2: "",
    total_star_3: "",
    recv_star: "받음!",
    album: "사진첩",
    jjim: "찜하기",
    message: "쪽지전송",
    video: "영상신청",
    intro: "소개글",
    average_video_time: "평균영상시간",
    video_time_below_15s: "15초미만",
    video_time_below_1min: "1분미만",
    video_time_below_3min: "3분미만",
    video_time_above_3min: "3분이상",
    average_review_point: "평균영상점수",
    favourite_factor: "호감도",
    belief_factor: "신뢰도",
    manner_factor: "매너도",
    beauty_factor: "미모도",
    delete: "삭제",
    msg: '메세지',
  },
  photo: {
    title: "ViewChat <b>Moment</b> Contents",
    subtitle: "뷰챗에서 특별한 일상을 공유하세요.",
    category: "전체_프로필",
    category_types: "all1_zzalbe_gif_recommend_me",
    create: "게시물 등록",
    guide: "여기 사진들입니다",
    hit: "조회수 ",
    write_title: "모멘트 만들기",
    write_guide: "이미지 없이는 만들 수 없습니다.",
    write_desc: "재미있는 콘텐츠를 바로 만들어 보세요.",
    visit: "방문수",
    recommend: "추천",
    pick_photo: "사진을 등록하려면 클릭하십시오",
    delete_success: "삭제하였습니다.",
    emoticon_delete: "이모티콘 삭제",
    emoticon_delete_confirm: "삭제하시겠습니까?",
    emoticon_delete_success: "삭제",
    upload: "업로드",
    bookmark: "북마크",
    like: "좋아요",
    gift: "선물",
    report: "신고하기",
    prev_contents: "이전 컨텐츠",
    next_contents: "다음 컨텐츠",
    content_hashtag: '컨텐츠와 해시태그',
    hashtag: '# 해시태그',
    hashtag_hint: '* 선택한 해시태그는 다시 선택하면 해제됩니다.',
    public_options: '공개 옵션',
    free: '무료',
    followers: '팔로워(무료)',
    paid: '팔로워(유료)',
    paid_hint: '* 유료 가이드라인',
    hashtag_select: '해시태그 선택',
    hashtag_select_hint: '해시태그는 3개까지 선택가능합니다.',
    price_guideline: '유료 가이드라인',
    price_guideline_photo_1: '사진 한장: ',
    price_guideline_photo_2: '20 {price} 꽃다발',
    price_guideline_video_1: '동영상 한개: ',
    price_guideline_video_2: '60 {price} 꽃다발',
    price_guideline_audio_1: '음성 한개: ',
    price_guideline_audio_2: '30 {price} 꽃다발',
    price_must_be_nonzero: '가격은 0이 될 수 없습니다.',
    price_must_below: '가격은 {maxPrice} 이하로 가능합니다.',
    public_to_followers: '팔로워 공개',
  },
  video: {
    video_call_allow_necessary_permissions: "권한을 허락해 주십시오",
    video_01: "{nickname}과의 영상 통화가 취소되었습니다",
    video_02: "종료확인",
    video_03: "화상채팅을 정말 끝내려고요?",
    video_05: "화상채팅을 끝내세요",
    video_07: "채팅하기에 포인트가 부족합니다!",
    video_08: "축하해요! {cash}을(를) 벌었네요.",
    video_09: "{nickname}의 포인트가 부족하여 비디오 채팅을 마쳤습니다",
    video_10: "화상채팅요청",
    video_11: "포인트가 부족합니다.",
    video_12: "어머나! 부재중 입니다. 잠시후에 다시 신청하여 주세요.",
    video_14: "어머나! 부재중 입니다. 잠시후에 다시 신청하여 주세요.",
    video_22: "거절",
    video_23: "승낙",
    video_24: "새 비디오 요청",
    video_25: "새 글로벌 비디오 요청",
    video_26: "({second}초 후 자동으로 거부됨)",
    video_27: "성별이 같아요. 다른 성별에 대해서 요청해주세요.",
    video_28: "웹 카메라를 사용할 수 없습니다.",
    video_profile_02: "{nickname}님을 찜목록에 추가하였습니다",
    global_user: "글로벌 유저",
    global_user_warning: "글로벌 유저입니다.<br/>2배의 포인트가 부과됩니다.",
    video_call_request: "화상 채팅 요청...",
    video_waiting_time_out: "요청 시간 초과. 나중에 다시 시도하세요.",
    review_title: "화상채팅은 어땠어?",
    review_select_rate: "'{review}' 점수를 선택하십시오",
    review_select_rate_1: "아래에서 선택하세요.",
    review_select_rate_2: "<b>점수는 누적되지 않았습니다.</b><br/>정확하게 평가해 주세요.",
    review_skip: "건너뛰기",
    review_success: "평가 하였습니다.",
    video_call_received: '화상채팅 요청',
    video_call_finished: '영상채팅종료 {time}',
  },
  message: {
    message_09: "모든 메시지 삭제",
    message_10: "모든 메시지 읽기",
    message_11: "여기 메시지가 있습니다.",
    message_read: "읽음",
    message_unread: "읽지 않음",
    message_delete_title: "메시지삭제",
    message_delete_confirm: "모든 메시지를 삭제하시겠습니까?",
    message_read_title: "메시지 읽기",
    message_read_confirm: "모든 메시지를 다 읽으시겠습니까?",
    message_sent_photo: "사진 보내기",
    message_sent_gift: "선물 보내기",
    message_content_delete: "콘텐츠삭제",
    message_content_delete_confirm: "메시지를 삭제하시겠습니까?",
    message_content_placeholder: "메시지를 입력해주세요",
    message_content_hint: "메시지를 입력하십시오",
    msg_send_msg_disabled: "메시지를 보낼 수 없습니다.",
    msg_block_user: "{nickname}이(가) 차단되었습니다.",
    msg_like_success: "추천하였습니다.",
    msg_sent_message: "메시지를 성공적으로 보냈습니다",
    photo: "사진",
    jjim: "찜",
    block: "차단하기",
    report: "신고하기",
    message_input: "메시지를 입력하세요.",
    send_message: "메시지 보내기",
    send_desc: "{point}P이(가) 청구됩니다.",
    unread_messages: '읽지 않은 메시지',
    viewchat_messages: '뷰챗을 즐기세요 <b>쪽지함</b>',
    viewchat_messages_intro: '회원과 공유하는 대화 메시지들입니다. 좋은 대화 나누세요!',
    viewchat_messages_info: '총 <b>{total_count}</b>메시지와 <b>{unread_count}</b>읽지 않은 메시지가 있습니다.',
    warning_message: '청소년보호법 및 각 심사기관에 따른 규정을 위반한 경우 사용제한 및 법적 처벌을 받을 수 있습니다.',
  },
  profile: {
    title: "프로필 편집",
    average_video_time: "평균 영상 시간",
    average_review_point: "평균 리뷰 포인트",
    short_average_video_time: "평균 영상 시간",
    short_average_review_point: "평균 리뷰 포인트",
    video_time_below_15s: "15초 미만",
    video_time_below_1min: "1분 미만",
    video_time_below_3min: "3분 미만",
    video_time_above_3min: "3분 이상",
    favourite_factor: "호감도",
    belief_factor: "신뢰도",
    manner_factor: "매너도",
    beauty_factor: "미모도",
    profile_intro: "소개글",
    profile_notify: "알림",
    profile_12: "닉네임",
    profile_13: "8자 이내로 입력해주세요",
    profile_14: "나이",
    profile_15: "성별",
    profile_16: "(변경불가)",
    profile_23: "저장",
    profile_24: "수정",
    profile_25: "영상프로필",
    profile_26: "기본 아이콘",
    dibs: "찜",
    recommend: "추천",
    album: "앨범",
    message: "쪽지전송",
    voice_chat: "음성채팅",
    video_chat: "영상채팅",
    favorite: "호감도",
    belive: "신뢰도",
    manner: "매너도",
    beauty: "미모도",
    photos: "사진",
  },
  album: {
    title: "앨범",
    confirm: "삭제하시겠습니까?"
  },
  more: {
    more_09: "충전하기",
    more_10: "선물아이템",
    more_11: "기프티콘",
    more_13: "사용내역",
    more_14: "문의하기",
    more_15: "이용안내",
    more_16: "공지사항",
    more_21: "버전체크",
    more_22: "최신버전을 체크합니다.",
    more_30: "나의 앨범",
    more_31: "출석체크",
    more_32: "공지사항",
    more_33: "공유하기",
    more_34: "공유하고 포인트를 받으세요.",
    more_35: "프로필 수정",
    more_36: "주의하세요!",
    more_37: "피싱사기에 주의하세요",
    more_38: "회원",
    more_39: '50 포인트',
    more_40: '계정설정',
    more_41: '전환',
    more_42: '영상알림 ON',
    more_43: '추천',
    more_44: '알림설정',
    more_45: '온라인 친구가 되고 싶은 사람?',
    more_46: '새로운 피싱 사기를 조심하세요!',
    more_47: '다른 메신저나 앱으로 유도할 때는 피싱 사기를 의심해야 한다.',
    leave: '회원탈퇴',
    leave_message: '회원 탈퇴 시 보유하고 있는 포인트 및 캐쉬가 모두 소멸되며, 탈퇴 후 150일이 지나고 재가입이 가능합니다.\n\n정말 회원탈퇴 하시겠습니까?',
    refund: '환급신청',
  },
  zzalbe: {
    message: "메시지",
    gift: "선물",
    delete: "삭제",
    reply: "답글",
    msg: "Msg",
  },
  notify: {
    setting_video_receive: "영상알림",
    setting_video_receive_global: "글로벌 영상알림",
    setting_sound: "소리",
    setting_vibration: "진동",
    setting_on: "ON",
    setting_off: "OFF",
  },
  log: {
    title: "사용내역",
    log_list_02: "포인트 사용내역",
    log_list_03: "캐시 사용내역",
    point: "포인트: ",
    cash: "캐시: "
  },
  charge: {
    title: "충전",
    charge_please: "직접 충전 안내",
    direct_request: "직접 요청",
    desc1: "Account : NewYorkBank  304101-04-349026",
    desc2: "입금하실 아이템을 선택 후 입금자명을 남겨주시면 확인 후 처리해드리겠습니다.",
    by_app: "by App",
    open_app: "앱 열기",
    account_holder_name: "예금주명",
    input_name: "(이름을 입력하세요.)",
    input_phone_number: "(전화번호를 입력하세요.)",
    direct_success: "신청성공",
    amount: "금액:",
    charge_point: "포인트:",
    charge_items: "아이템을 충전해 주세요",
    charge_direct: "직접<br/>충전",
    charge_purchase: "구매하기",
    charge_discount: "할인",
    charge_item_price: "$",
    vip_item_price: "W",
    charge_item_point: "P",
    charge_vip_title: "VIP 항목",
    charge_vip_comment: "(VIP 사용자 전용)",
    charge_vip_unavailable: "사용불가",
    charge_vip1_price: "50,000W",
    charge_vip2_price: "100,000W",
    charge_vip1_point: "35,000P",
    charge_vip2_point: "70,000P",
    charge_11: "무료충전소",
    charge_12: "무료충전소 1",
    charge_14: "무료충전소 2",
    charge_15: "무료충전소 3",
    charge_16: "전환하기",
    charge_17: "캐시 > 포인트",
    charge_18: "포인트 > 캐시",
    purchase_success: "{point} 포인트를 {nickname}에게 충전하였습니다.",
    paypal_purchase_rewarded: "{point} 포인트가 지급되었습니다.",
    message_goto_app: '이 기능은 슈퍼캠 앱에서만 사용할 수 있습니다. 앱을 열어보시겠어요?',
    paypal_purchase_success: '구매성공!\n 유료 포인트가 지급되기 전까지 잠시만 기다려주세요.',    
    guide_title: '포인트 안내',
    guide_text1: '1. 본 서비스는 기본 적으로 무료 입니다. 다만, 일부 기능을 이용하기 위해서는 포인트를 이용하여 사용 할 수 있습니다.',
    guide_text2: '2. DM 보내기 와 영상 통화 또는 선물 아이템은 포인트를 이용하여 구매 또는 기능을 사용할 수 있습니다.<br/>DM 보내기 - 하나에 15P 사용됩니다.<br/>영상통화 - 1분에 200P 필요합니다.<br/>선물아이템 - 아이템 종류에 따라 10 ~ 900P 필요합니다.',
    item_select: '아이템',
    item4_hint: '3,000P おトク‼',
    item3_hint: '1,000P おトク‼',
    item2_hint: '¥ 1,000 おトク‼',
    item1_hint: '人気No.1',
    item0_hint: 'お試し価格',
  },
  gifticon: {
    gifticon_03: "이름",
    gifticon_04: "가격",
    gifticon_05: "전화번호",
    gifticon_06: "구매하기",
    gifticon_07: "안내",
    gifticon_10: "구매",
    gifticon_11: "브랜드",
    gifticon_12: "수수료",
    gifticon_13: "만료일",
    gifticon_14: "만료일은 ",
    gifticon_15: "전화번호를 입력하세요.",
    gifticon_16: "기프티콘 구매",
    gifticon_02: "신청하였습니다.",
    request_gifticon: "기프티콘 신청",
    category_select: "카테고리를 선택하세요.",
    category_1: "상품",
    category_2: "가게",
    category_3: "쿠폰",
    category_4: "패스트푸드",
    category_5: "빵",
    category_6: "커피",
    category_7: "식당",
    category_8: "사진",
    category_9: "미용",
    category_10: "여행",
    category_11: "디지털",
    category_12: "아기",
  },
  refund: {
    refund_agree_10: "환불신청",
    refund_agree_11: "주의사항",
    refund_agree_12: "안내문구",
    refund_agree_13: "안내",
    refund_agree_14: "안내문구",
    refund_03: "계정 소유자명을 입력하세요",
    refund_04: "주민번호를 입력하세요.",
    refund_05: "전화번호를 입력하세요.",
    refund_06: "주소를 입력하세요.",
    refund_08: "계좌번호를 입력하세요.",
    refund_10: "30,000 이상부터 가능합니다.",
    refund_11: "캐시가 부족합니다.",
    refund_12: "환급신청 성공.",
    refund_13: "나의 캐시",
    refund_14: "계정 소유자",
    refund_15: "전화번호",
    refund_16: "주민번호",
    refund_18: "주소",
    refund_19: "은행",
    refund_20: "계좌번호",
    refund_21: "계좌번호를 입력하세요",
    refund_22: "환급캐시 - (30,000 이상부터)",
    refund_27: "환급금액",
    refund_28: "환급신청",
    refund_29: "은행선택",
    refund_30: "(보너스",
    refund_31: "추가)",
    refund_bank: "bank1_bank2_bank3_bank4",
    owner: "예금주 입력",
    phone_desc: "휴대폰번호를 숫자로만 입력",
    resident_desc: "(-)하이픈을 제외한 숫자로만 입력)",
    address_desc: "주소를 입력해주세요",
    cash_desc: "신청캐시 직접 입력(만원 단위로 작성)",
    top_hint: '当日振込をご希望の場合は20時までに振込申請をしていただければ当日振込可能です。',
    middle_hint: '入金申請したキャッシュ-（1万キャッシュ以上から申請可能です）',
    bottom_hint: '【振込手数料】恐れ入りますが振込手数料はお客様の負担でお願いいたします。',
  },
  category: {
    category_1: "상품",
    category_2: "가게",
    category_3: "쿠폰",
    category_4: "패스트푸드",
    category_5: "빵",
    category_6: "커피",
    category_7: "식당",
    category_8: "영화",
    category_9: "미용",
    category_10: "여행",
    category_11: "디지털",
    category_12: "육아",
  },
  transfer: {
    title: "전환하기",
    bread: "빵: ",
    point2bread: "포인트를 빵으로 변경",
    bread2point: "빵에서 포인트로 변경",
  },
  ask: {
    ask_email: "이메일",
    ask: "문의하기",
    desc: "정확하게 입력하세요.",
    asked: "문의하였습니다.",
    asked_message1: "ㆍ Message1.",
    asked_message2: "ㆍ Message2.",
    asked_message3: "ㆍ Message3.",
    ok: "확인",
    ask_category: "카테고리",
    ask_content: "내용",
    ask_01: "이메일 주소를 입력하세요.",
    ask_02: "카테고리를 선택하세요.",
    ask_03: "문의내용을 입력하세요.",
    ask_04: "영수증사진을 선택하세요.",
    ask_05: "No answer...",
    ask_06: "문의",
    ask_08: "문의하였습니다.",
    ask_09: "이메일 주소를 입력하세요.",
    ask_11: "이미지 업로드: ",
    ask_12: "* 주의사항...",
    ask_13: "답변체크",
    ask_14: "회원탈퇴",
    ask_15: " ",
    agree: "다음사항에 동의합니다",
    agree_content: "문의 및 답변은 이메일로 발송되며, 이메일은 회신을 위해서만 수집된다.",
    upload_desc: "조회와 관련이 없는 내용,이미지,비속어는 무시됩니다"
  },
  announce: {
    title: "알림",
  },
  report: {
    title: "신고하기",
    warning: "신고사유를 선택하세요.",
    reason: "불쾌한 내용_성희롱_성적발전_스팸_기타",
    placeholder: '신고사유',
    please_select_category: '신고사유를 선택하세요.',
    success: "신고하였습니다.",
  },
  notify_setting: {
    title: "알림 설정",
    video_notify: "영상알림",
    video_notify_global: "글로벌 영상알림",
    video_notify_sound: "음성알림",
    video_notify_vibrate: "진동알림",
  },
  country: {
    title: "모든 국가",
    search: "찾기",
    real_country: "실시간 국가",
    rank: "순위",
    more_country: "더 많은 국가",
    max_country: "최대 5개 국가",
    hot: "인기순",
    alpha: "자모순",
    thail: "태국",
    viet: "베트남",
    rus: "러시아",
    real_coun: "실시간 인기",
    country1: "Thailand",
    country2: "Uqurain",
    country3: "Russian",
    country4: "United States",
    country5: "Tailwan",
    country6: "AAA",
    warning1: "단 한 나라만",
    country_code_list: "tha_uga_rus_usa_twn_alb",
    country_list: "Thailand_Uganda_Russian_United States_Taiwan_AAAA",
    all_country: "모든 국가",
    apply: "적용",
    turn_off_global: "글로벌 모드 해제",
  },
  guide: {
    title: "안내",
    usage_agreement: "이용약관",
    privacy_policy: "개인정보 보호정책",
  },
  gift_items: {
    title: '선물아이템',
    buy: '구매하기',
    fingerheart: '손하트',
    flowers: '꽃다발',
    ring: '다이아반지',
    palace: '궁전',
    megaheart: '메가하트',
    gift_ttem_names: ['손하트', '꽃다발', '다이아반지', '궁전', '메가하트'],
  },
  gift_item_purchase: {
    title: '선물아이템 구매하기',
    amount: "금액:",
    price: "포인트:",
  },
  follow: {
    follower: '팔로워',
    following: '팔로잉',
    block: '차단',
  },
  album: {
    profile_edit: '프로필 수정',
    write: '게시물 작성',
    posts: '게시물',
    likes: '좋아요',
    followers: '팔로워',
    followings: '팔로잉',
    motto_hint: '소개글 입력.',
    input_motto: '소개글을 입력하세요!',
    save: '저장',
    video_call: '영상신청',
    send_message: '쪽지전송',
    follow: '팔로우',
    unfollow: '언팔로우',
    must_follow_each_other_text1: '팔로워 전용 컨텐츠입니다.',
    must_follow_each_other_text2: '상대를 팔로우하고 컨텐츠를 확인해보세요!',
    no_photos: '게시물이 없습니다.',
  },
  story_purchase: {
    title: '해당 컨텐츠를\n구매하시겠습니까?',
    description1: '구매하신 컨텐츠는 앱 내에서만 소장할 수 있습니다.',
    description2:
      '저장 및 유출을 금지하며, 위반시 모든 책임은 사용자에게 있습니다.',
    item_not_enough: '아이템이 부족합니다.',
    purchase_confirm: '구매하시겠습니까?',
    purchase_success: '스토리 아이템을 구매하였습니다.',
  },
};
