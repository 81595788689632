<template>
  <div v-if="show" class="video-chat-content">
    <div id="videos">
      <video id="mini-video" autoPlay playsinline muted style="object-fit:contain;"></video>
      <video id="remote-video" autoPlay playsinline style="object-fit:contain;"></video>
      <video id="local-video" autoPlay playsinline muted style="object-fit:contain;"></video>
    </div>
    <div class="top-bar">
      <div class="user-info">
        <UserImage :gender="userInfo.user_sex" :image="userInfo.user_profile_image"/>
        <label class="nickname">{{ userInfo.user_nickname }}</label>
        <label class="gender-like">
          {{ userInfo.user_sex == 0 ? $t('common.male_short') : $t('common.female_short') }}{{ userInfo.user_age }}
          &nbsp;/&nbsp;{{ $t('common.like') }} {{ currency(userInfo.user_like_cnt) }}
        </label>
      </div>
      <button class="button btn-report" @click="onReport">
        <img srcset="../../assets/img/report@3x.png 3x"/>
      </button>
    </div>
    <button class="button btn-jjim" @click="onJjim">
      <img srcset="../../assets/img/live_jjim@3x.png 3x"/>
      {{ $t('common.album_jjim') }}
    </button>
    <label class="time">{{ curTimeString }}</label>
    <div class="bottom-bar">
      <button class="button btn-mic" @click="onMic">
        <img :srcset="require(micOn ? '../../assets/img/video_chat_mic_on.png' : '../../assets/img/video_chat_mic_off.png')" style="width:48px;height:48px;"/>
      </button>
      <button class="button btn-call" @click="onHangout">
        <img srcset="../../assets/img/video_hangout@3x.png 3x"/>
      </button>
      <button class="button btn-camera" @click="onCamera">
        <img srcset="../../assets/img/video_chat_front_camera.png" style="width:48px;height:48px;"/>
      </button>
    </div>
    <label id="watermark-label0" class="watermark-red">{{ myInfo.uid }}<br/>{{ myInfo.user_nickname }}</label>
    <label id="watermark-label1" class="watermark-blue">{{ myInfo.uid }}<br/>{{ myInfo.user_nickname }}</label>
    <label id="watermark-label2" class="watermark-red">{{ myInfo.uid }}<br/>{{ myInfo.user_nickname }}</label>
    <label id="watermark-label3" class="watermark-blue">{{ myInfo.uid }}<br/>{{ myInfo.user_nickname }}</label>
    <label id="watermark-label4" class="watermark-red">{{ myInfo.uid }}<br/>{{ myInfo.user_nickname }}</label>
  </div>
</template>
<style lang="scss" scoped src="./VideoChat.scss"></style>
<script>
import UserImage from "../../components/UserImage";
import { pad, requestPost, showToast } from "../../lib/util";
// TODO: uncomment
import { AppController, parseJSON, sendAsyncUrlRequest, trace } from "../../lib/apprtc.debug";
import vchat from "../../lib/vchat";
import { EVENT_NAME } from "../../lib/constant";

export default {
  name: 'VideoChat',
  components: {
    UserImage,
  },
  props: {
    videoInfo: {
      type: Object,
      required: true,
      default() {
        return {
          roomUrl: '',
          roomId: '',
          peerUser: {},
          fromUser: {},
          maxVideoTime: '',
          pointVideo: 0,
          chattingWaitTime: 0,
          freeChatTime: 0
        };
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  data() {
    const myInfo = this.$store.getters.me;
    return {
      myInfo: myInfo,
      roomUrl: this.videoInfo.roomUrl,
      roomId: this.videoInfo.roomId,
      deviceTime: this.videoInfo.roomId ? this.videoInfo.roomId.split('_')[1] : '',
      peerUser: this.videoInfo.peerUser,
      fromUser: this.videoInfo.fromUser,
      userInfo: this.videoInfo.peerUser ? this.videoInfo.peerUser.uid == myInfo.uid ? this.videoInfo.fromUser : this.videoInfo.peerUser : {},
      maxVideoTime: this.videoInfo.maxVideoTime,
      pointVideo: this.videoInfo.pointVideo,
      chattingWaitTime: 10000,//this.videoInfo.chattingWaitTime,
      freeChatTime: this.videoInfo.freeChatTime,
      curTime: 0,
      videoTimer: null,
      waitingTimer: null,
      finishTimer: null,
      appController: null,
      micOn: true,
    }
  },
  watch: {
    show(show) {
      if (show) {
        // dialog show
        document.body.style.overflow = 'hidden';

        this.curTime = 0;
        this.videoTimer = null;
        this.waitingTimer = null;
        this.finishTimer = null;
        this.appController = null;
        this.initVideoInfo();
        this.startWaitingTimer();

        this.emitter.on(EVENT_NAME.REPORT_SUCCESS, this.onReportSuccess);

        setTimeout(() => {
          this.connect();
        }, 1000);
      } else {
        // dialog hide
        document.body.style.overflow = 'auto';
        this.emitter.off(EVENT_NAME.REPORT_SUCCESS, this.onReportSuccess);
      }
    }
  },
  computed: {
    curTimeString() {
      let second = this.curTime % 60;
      let minute = Math.floor(this.curTime / 60) % 60;
      let hour = Math.floor(this.curTime / (60 * 60)) % 24;

      return `${pad(hour, 2)}:${pad(minute, 2)}:${pad(second, 2)}`;
    },
  },
  mounted() {
  },
  methods: {
    initVideoInfo() {
      this.myInfo = this.$store.getters.me;
      this.roomUrl = this.videoInfo.roomUrl;
      this.roomId = this.videoInfo.roomId;
      this.deviceTime = this.videoInfo.roomId ? this.videoInfo.roomId.split('_')[1] : '';
      this.peerUser = this.videoInfo.peerUser;
      this.fromUser = this.videoInfo.fromUser;
      this.userInfo = this.videoInfo.peerUser ? this.videoInfo.peerUser.uid == this.myInfo.uid ? this.videoInfo.fromUser : this.videoInfo.peerUser : {};
      this.maxVideoTime = this.videoInfo.maxVideoTime;
      this.pointVideo = this.videoInfo.pointVideo;
      this.chattingWaitTime = this.videoInfo.chattingWaitTime;
      this.freeChatTime = this.videoInfo.freeChatTime;
      this.curTime = 0;
      this.videoTimer = null;
      this.waitingTimer = null;
      this.finishTimer = null;
      this.appController = null;
    },
    onReport() {
      this.$root.showReportDlg(this.userInfo);
    },
    onReportSuccess() {
      this.onHangout();
    },
    onJjim() {
      let _app = this;
      requestPost(
        'jjim/createJjim',
        {
          uid: this.myInfo.uid,
          peer_uid: this.userInfo.uid
        },
        () => {
          showToast(_app.$t('video.video_profile_02', {nickname: _app.userInfo.user_nickname}));
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
    onMic() {
      if (this.appController.call_.isMicOn() == -1) {
        return;
      }
      
      this.appController.call_.toggleAudioMute();
      this.micOn = !this.micOn;
    },
    onHangout() {
      this.appController.hangup_();
    },
    onCamera() {
      showToast(this.$t('msg.not_implemented_yet'));
    },
    connect() {
      let _app = this;
      let loadingParams = {
        errorMessages: [],
        roomId: this.roomId,
        roomServer: this.roomUrl,
        connect: false,
        paramsFunction: function () {
          return new Promise(function (resolve, reject) {
            trace('Initializing; retrieving params from: ' + _app.roomUrl + '/params');
            sendAsyncUrlRequest('GET', _app.roomUrl + '/params').then(function (result) {
              let serverParams = parseJSON(result);
              let newParams = {};
              if (!serverParams) {
                resolve(newParams);
                return;
              }

              // Convert from server format to expected format.
              /* jshint ignore:start */
              //jscs:disable requireCamelCaseOrUpperCaseIdentifiers
              newParams.isLoopback = serverParams.is_loopback === 'true';
              newParams.mediaConstraints = parseJSON(serverParams.media_constraints);
              newParams.offerConstraints = parseJSON(serverParams.offer_options);
              newParams.peerConnectionConfig = parseJSON(serverParams.pc_config);
              newParams.peerConnectionConstraints = parseJSON(serverParams.pc_constraints);
              newParams.turnRequestUrl = serverParams.turn_url;
              newParams.turnTransports = serverParams.turn_transports;
              newParams.wssUrl = serverParams.wss_url;
              newParams.wssPostUrl = serverParams.wss_post_url;
              newParams.versionInfo = parseJSON(serverParams.version_info);
              //jscs:enable requireCamelCaseOrUpperCaseIdentifiers
              /* jshint ignore:end */
              newParams.messages = serverParams.messages;

              resolve(newParams);
            }).catch(function (error) {
              trace('Initializing; error getting params from server: ' +
                error.message);
              reject(error);
            });
          });
        }
      };
      let callbackParams = {
        onConnected: this.onConnected,
        onDisconnected: this.onDisconnected,
      };
      _app.appController = new AppController(loadingParams, callbackParams);
    },
    onConnected() {
      initialiseFloatingImages();
      this.stopWaitingTimer();
      this.startVideoTimer();
      this.startFinishTimer(this.maxVideoTime * 1000);
    },
    onDisconnected() {
      this.stopVideoTimer();
      this.stopFinishTimer();
      vchat.doEndVideoChat(this.deviceTime, this.curTime, this.peerUser);
      this.$emit('close');
    },
    startWaitingTimer() {
      let _app = this;
      this.waitingTimer = setTimeout(function () {
        showToast(_app.$t('video.video_waiting_time_out'));
        _app.disconnect();
      }, this.chattingWaitTime * 1000);
    },
    stopWaitingTimer() {
      if (this.waitingTimer) {
        clearTimeout(this.waitingTimer);
        this.waitingTimer = null;
      }
    },
    startVideoTimer() {
      let _app = this;
      this.videoTimer = setInterval(function () {
        _app.curTime += 1;
      }, 1000);
    },
    stopVideoTimer() {
      if (this.videoTimer) {
        clearInterval(this.videoTimer);
        this.videoTimer = null;
      }
    },
    startFinishTimer(timeout) {
      let _app = this;
      setTimeout(function () {
        _app.disconnect();
      }, timeout);
    },
    stopFinishTimer() {
      if (this.finishTimer) {
        clearInterval(this.videoTimer);
        this.finishTimer = null;
      }
    },
    disconnect() {
      this.stopVideoTimer();
      this.onHangout();
    }
  }
}

//
// [2022/04/14 09:54 KSH]워터마크 효과.
// https://drewnoakes.com/code/javascript/bubbles.html
//

/*
Drew Noakes 6 Apr 2002 http://drewnoakes.com
- Images now can go up and down (allowing bubbles, as well as snow)
- Images move into the screen and move out smoothly, without disappearing or
  appearing suddenly
- Scrolling the page doesn't effect the appearance of the snow/bubbles
- renamed variables to be more meaningful
- refactored common code out to functions, replacing different repeating
  functions for each browser with a single function (moveFloatingImages)
  for all browsers

Modified 8 Dec 2006 for XHTML support.  This required the 'top' and 'left' attributes
be set with a "px" suffix which in turn requires parsing by the CSS engine, reducing
performance.  If you don't use XHTML validation, remove the string appends below.
*/

const imageHeight = 16;
// number of images to display
const imageCount = 5;
// -1 for up, 1 for down
const imageDirection = -1;

// time to wait before queueing the next screen update
const TIMEOUT_INTERVAL_MILLIS = 10;

// browser sniffer
const ns4up = (document.layers) ? 1 : 0;
const ie4up = (document.all) ? 1 : 0;
const ns6up = (document.getElementById && !document.all) ? 1 : 0;

// coordinate and position arrays
const thetaRadians = [];
const xPosition = [];
const yPosition = [];

// amplitude and step arrays
const xAmplitude = [];
const thetaStep = [];
const yStep = [];

// window size variables, set by function detectWindowSize()
let windowWidth, windowHeight;

// create DIVs and start the function
function initialiseFloatingImages()
{
  detectWindowSize();

  for (let i = 0; i < imageCount; i++) {
    // set coordinate variables
    thetaRadians[i] = 0;
    // set position variables
    xPosition[i] = Math.random()*(windowWidth-50);
    yPosition[i] = Math.random()*windowHeight;
    // set amplitude variables
    xAmplitude[i] = Math.random()*20;
    // set step variables
    thetaStep[i] = 0.02 + Math.random()/10;
    // set step variables
    yStep[i] = 0.7 + Math.random();
  }

  moveFloatingImages();
}

// this is the main function
function moveFloatingImages()
{
  // for each image...
  for (let i = 0; i < imageCount; i++) {
    // recalculate y position
    yPosition[i] += imageDirection * yStep[i];
    // ensure not off top or bottom of visible screen
    if (yPosition[i] > windowHeight+getPageYOffset()) {
      // downwards-heading image is at the bottom...  reset it
      xPosition[i] = Math.random()*(windowWidth-xAmplitude[i]-30);
      yPosition[i] = -imageHeight;
      thetaStep[i] = 0.02 + Math.random()/10;
      yStep[i] = 0.7 + Math.random();
      detectWindowSize();
    } else if (yPosition[i] < getPageYOffset()-imageHeight) {
      // upwards-heading image is at the top...  reset it
      xPosition[i] = Math.random()*(windowWidth-xAmplitude[i]-30);
      yPosition[i] = getPageYOffset() + windowHeight;
      thetaStep[i] = 0.02 + Math.random()/10;
      yStep[i] = 0.7 + Math.random();
      detectWindowSize();
    }
    thetaRadians[i] += thetaStep[i];
    // move each image
    const newXPosition = xPosition[i] + xAmplitude[i] * Math.sin(thetaRadians[i]);
    document.getElementById("watermark-label"+i).style.top = yPosition[i] + "px";
    document.getElementById("watermark-label"+i).style.left = newXPosition + "px";
  }
  setTimeout(moveFloatingImages, TIMEOUT_INTERVAL_MILLIS);
}

// return the page's offset due to vertical scrolling
function getPageYOffset()
{
  let yOffset = 0;
  if (ns4up) {
    yOffset = window.pageYOffset;
  } else if (ie4up||ns6up) {
    yOffset = document.body.scrollTop;
  }
  return yOffset;
}

// detect information about the window's size
function detectWindowSize()
{
  if (ns6up) {
    windowWidth = window.innerWidth;
    windowHeight = window.innerHeight;
  } else if (ns4up) {
    windowWidth = document.body.clientWidth;
    windowHeight = document.body.clientHeight;
  } else if (ie4up) {
    windowWidth = document.body.clientWidth;
    windowHeight = document.body.clientHeight;
  } else {
    windowWidth = 800;
    windowHeight = 600;
  }
}
</script>
