<template>
  <transition name="fade">
    <div v-if="show" class="pop_back" @click="$emit('close')">
      <div class="pop_chat" @click="$event.stopPropagation()">
        <div class="chat_top">
          <UserImage :gender="userInfo.user_sex" :image="userInfo.user_profile_image"/>
          <p>{{ userInfo.user_nickname }}</p>
          <span :class="[{male: userInfo.user_sex == '0', female: userInfo.user_sex == '1'}]">
            ({{
              userInfo.user_age + $t('common.years') + ' ' +
              (userInfo.user_sex == '0' ? $t('common.male') : $t('common.female'))
            }})
          </span>
          <div class="right_buttons">
            <a href="javascript:" @click="onVideo">
              <img src="../../assets/img/ico_video.png">
            </a>
            <a href="javascript:" @click="onReport">
              <img src="../../assets/img/ico_report.png">
            </a>
            <a href="javascript:" @click="$emit('close')">
              <img class="close" src="../../assets/img/btn_close.png">
            </a>
          </div>
        </div>
        <div class="chat_box">
          <div class="scroll">
            <ul>
              <li v-for="(message, idx) in messages" :key="idx"
                  :class="[{you: !message.isTitle && message.type == 'received'}, {date: message.isTitle}]">
                <label v-if="message.isTitle">{{ message.text }}</label>
                <div v-else>
                  <img v-if="message.imageSrc != ''" :src="message.imageSrc" @click="onImage(message.imageSrc)"/>
                  <p v-else>{{ message.text }}</p>
                  <span>{{ message.footer }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="chat_input">
          <a href="javascript:" @click="onMore">
            <img src="../../assets/img/ico_chat_plus.png">
          </a>
          <input type="text" :placeholder="$t('message.message_input')"
                 ref="message" v-model="message">
          <a href="javascript:" @click="onSend">
            <img src="../../assets/img/ico_chat_send.png">
          </a>
        </div>
        <div v-if="showFab" class="chat_btn" @click="showFab = false">
          <a href="javascript:" @click="onGallery">
            <img src="../../assets/img/ico_photo.png">{{ $t('message.photo') }}
          </a>
          <a href="javascript:" @click="onJjim">
            <img src="../../assets/img/ico_pro05.png">{{ $t('message.jjim') }}
          </a>
          <a href="javascript:" @click="onBlock">
            <img src="../../assets/img/ico_block.png">{{ $t('message.block') }}
          </a>
          <a href="javascript:" @click="onReport">
            <img src="../../assets/img/ico_report.png">{{ $t('message.report') }}
          </a>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped src="./MessageDetail.scss"></style>

<script>
import { EVENT_NAME, OFFLINE_MODE, UPLOAD_MOMENT_IMAGE } from "../../lib/constant";
import { requestPost, requestUpload, setMomentLocale, showToast } from "../../lib/util";
import dao from "../../lib/dao";
import xmpp from "../../lib/xmpp";
import UserImage from "../../components/UserImage";
import CountryFlag from '../../components/CountryFlag';

const CHAT_DATE_FORMAT = 'YYYY-MM-DD';
setMomentLocale();

export default {
  name: 'MessageDetail',
  components: {
    UserImage,
    CountryFlag,
  },
  props: {
    user: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  data() {
    return {
      myInfo: this.$store.getters.me,
      messages: [],
      message: '',
      showFab: false,
      backButtonRouteGuard: null,
    }
  },
  computed: {
    userInfo() {
      return this.user;
    }
  },
  watch: {
    show(show) {
      if (show) {
        // dialog show
        this.emitter.on(EVENT_NAME.NEW_MESSAGE_TEXT, this.onNewMessageText);
        this.emitter.on(EVENT_NAME.NEW_MESSAGE_IMAGE, this.onNewMessageImage);
        this.emitter.on(EVENT_NAME.REPORT_SUCCESS, this.onReportSuccess);
        document.addEventListener('keyup', this.onKeyPress);
        // back button
        this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
          this.$emit('close');
          next(false);
        });
        this.getList();
      } else {
        // dialog hide
        this.emitter.off(EVENT_NAME.NEW_MESSAGE_TEXT, this.onNewMessageText);
        this.emitter.off(EVENT_NAME.NEW_MESSAGE_IMAGE, this.onNewMessageImage);
        this.emitter.off(EVENT_NAME.REPORT_SUCCESS, this.onReportSuccess);
        this.emitter.emit(EVENT_NAME.MESSAGE_DETAIL_BACK);
        document.removeEventListener('keyup', this.onKeyPress);
        // back button
        if (this.backButtonRouteGuard) {
          this.backButtonRouteGuard();
        }
      }
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        let me = document.querySelector('.pop_chat').parentElement;
        let activeDlgList = document.querySelectorAll('.pop_back');

        if (activeDlgList.item(activeDlgList.length - 1) == me) {
          this.$emit('close');
        }
      }
    },
    onVideo: function () {
      if (this.userInfo.uid == 0) {
        return;
      }

      this.$root.showUserDlg(this.userInfo);
    },
    onDelete: function () {
      this.$root.showConfirmDlg(
        '',
        this.$t('message.message_content_delete_confirm'),
        this.$t('common.ok'),
        this.$t('common.cancel'),
        () => {
          dao.deleteAllMessagesWithUser(this.userInfo.uid);
          dao.deleteAllVideoCallsWithUser(this.userInfo.uid);
          dao.deleteAllGiftItemsWithUser(this.userInfo.uid);
          this.messages = [];
        }
      );
    },
    onImage: function (url) {
      if (url != '') {
        window.open(url, '_blank');
      }
    },
    sendImage: async function (image) {
      if (!image) {
        showToast(this.$t('app_error.upload_file_not_exist'));
        return;
      }

      if (OFFLINE_MODE) {
        let _app = this;
        let fr = new FileReader();
        fr.onload = function () {
          _app.addMessage(1, 'image', fr.result);
        };
        fr.readAsDataURL(image);
        return;
      }

      let response = await requestUpload(image, {
        uid: this.myInfo.uid,
        category: UPLOAD_MOMENT_IMAGE
      });
      if (response) {
        this.addMessage(1, 'image', response);
      }
    },
    onGallery: function () {
      this.showFab = false;
      this.$root.takePhoto(img => {
        this.sendImage(img);
      });
    },
    onJjim: function () {
      this.showFab = false;
      let _app = this;
      requestPost(
        'jjim/createJjim',
        {
          uid: this.myInfo.uid,
          peer_uid: this.userInfo.uid
        },
        () => {
          showToast(_app.$t('video.video_profile_02', { nickname: _app.userInfo.user_nickname }));
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
    onBlock: function () {
      this.showFab = false;
      let _app = this;
      requestPost(
        'block/blockUser',
        {
          uid: this.myInfo.uid,
          peer_uid: this.userInfo.uid
        },
        () => {
          showToast(_app.$t('message.msg_block_user', { nickname: _app.userInfo.user_nickname }));
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
    onReport: function () {
      this.showFab = false;
      this.$root.showReportDlg(this.userInfo);
    },
    onReportSuccess: function () {
      this.$emit('close');
    },
    onMore: function () {
      this.showFab = !this.showFab;
    },
    onSend: function () {
      if (this.myInfo.send_message_enabled == 'N') {
        showToast(this.$t('message.msg_send_msg_disabled'));
        return;
      }

      let text = this.message.replace(/\n/g, '<br>').trim();
      if (!text.length) {
        showToast(this.$t('message.message_content_placeholder'));
        return;
      }

      this.addMessage(1, 'text', text);
      this.message = '';
      this.$refs.message.focus();
    },
    addMessage: function (isSent, type, text, status = 0) {
      let time = Date.now();

      if (isSent) {
        if (type == 'text') {
          xmpp.sendMessageText(this.userInfo, text);
        } else {
          xmpp.sendMessageImage(this.userInfo, text);
        }
      }

      dao.insertMessage(this.userInfo.uid, isSent, text, type, time, status);

      let chatDateList = this.messages.filter(it => it.isTitle);
      if (chatDateList.length > 0) {
        let lastChatDate = chatDateList.pop();

        if (lastChatDate.text != this.moment(time).format(CHAT_DATE_FORMAT)) {
          this.messages.push({
            text: this.moment(time).format(CHAT_DATE_FORMAT),
            isTitle: true
          });
        }
      } else {
        this.messages.push({
          text: this.moment(time).format(CHAT_DATE_FORMAT),
          isTitle: true
        });
      }

      this.messages.push({
        text: type == 'text' ? text : '',
        // footer: this.moment(time).format("HH:mm"),
        footer: this.moment(time).fromNow(),
        type: isSent ? 'sent' : 'received',
        isTitle: false,
        imageSrc: type == 'text' ? '' : text
      });
      this.scroll2Bottom();
    },
    getList: async function () {
      this.messages = [];
      let msgList = [];
      if (OFFLINE_MODE) {
        msgList = [...Array(20).keys()].map(idx => {
          return {
            time: new Date(),
            type: idx % 4 == 0 ? 'image' : 'text',
            message: idx % 4 == 0 ? 'http://192.168.0.176:8010/uploads/2022/12/29/16722962637842073.png' : 'Hi, I am message content',
            isSent: idx % 2,
          };
        });
      } else {
        msgList = await dao.getMessagesWithUser(this.userInfo.uid);
      }
      if (msgList) {
        let msgDate = '';
        msgList.forEach(msg => {
          let date = this.moment(msg.time).format(CHAT_DATE_FORMAT);
          if (msgDate != date) {
            this.messages.push({
              text: date,
              isTitle: true
            });
            msgDate = date;
          }
          this.messages.push({
            text: msg.type != 'image' ? msg.message : '',
            // footer: this.moment(msg.time).format("HH:mm"),
            footer: this.moment(msg.time).fromNow(),
            type: msg.isSent == 1 ? 'sent' : 'received',
            isTitle: false,
            imageSrc: msg.type != 'image' ? '' : msg.message
          });
        });
      }
      this.scroll2Bottom();
    },
    scroll2Bottom: function () {
      this.$nextTick(function () {
        let container = this.$el.querySelector(".chat_box");
        container.scrollTop = container.scrollHeight;
      });
    },
    onNewMessageText: function (packet) {
      if (packet.content == '###REMOVE_MESSAGE_CONTENT###') {
        return;
      }

      if (this.userInfo.uid == packet.fromUser.userid) {
        this.addMessage(0, 'text', packet.content);
      }
    },
    onNewMessageImage: function (packet) {
      if (this.userInfo.uid == packet.fromUser.userid) {
        this.addMessage(0, 'image', packet.content);
      }
    },
  }
}
</script>
