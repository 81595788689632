<template>
  <div class="main">
    <HeaderBar
      v-if="!isStartupStage"
      :active-tab="activeTabIdx"
      @hamClick="showHamMenu = true"
      @tabClick="onTab"/>

    <Gnb
      v-if="!isStartupStage"
      :active-tab="activeTabIdx"
      :show-mo-menu="showHamMenu"
      @closeMoMenu="showHamMenu = false"
      @tabClick="onTab" />

    <Contents/>


    <div v-if="authenticate">
      <MessageDetail
        :show="showMessageDetail"
        :user="messageUser"
        @close="showMessageDetail = false"/>
      <Follow
        :show="showFollowPopup"
        @close="showFollowPopup = false"/>
    </div>

    <VideoProfile
        :show="showUserModal"
        :user="userInfo"
        @close="showUserModal = false"/>

    <div v-if="authenticate">
      <SendMessage
        :show="showMessageSendModal"
        :user="messageInfo"
        @close="showMessageSendModal = false"/>
      <VideoReceive
        :room-id="roomId"
        :show="showVideoReceiveModal"
        :user="videoUserInfo"
        :user-stats="userStats"
        @action="onVideoAction"
        @close="showVideoReceiveModal = false"/>
      <VideoChat
        :show="showVideoChat"
        :video-info="videoData"
        @close="showVideoChat = false"/>
      <VideoReview
        :show="showVideoReviewModal"
        :user="reviewInfo"
        @close="onVideoReviewClose"/>
      <Report
        :show="showReportModal"
        :user="reportInfo"
        @close="showReportModal = false"
        @report="onReport"/>
      <Confirm
        :cancel="confirmCancel"
        :content="confirmContent"
        :ok="confirmOk"
        :show="showConfirmModal"
        :title="confirmTitle"
        @close="showConfirmModal = false"
        @ok="onConfirmOk"/>
    </div>
  </div>
  <Loading
    v-model:active="this.$store.getters.isLoading"
    :can-cancel="false"
    :is-full-page="true"
  />
</template>
<script>
import "@/assets/css/viewchat.scss";
import { EVENT_NAME, ROUTES, VIDEO_CHAT_GIFT_ITEM_POINTS } from "./lib/constant";
import dao from "./lib/dao";
import { requestPost, saveData } from "./lib/util";
import xmpp, { fromXmppUserInfo, PacketSubType } from "./lib/xmpp";
import HeaderBar from "./components/HeaderBar";
import Contents from "./components/Contents";
import VideoProfile from "./views/dialog/VideoProfile";
import Report from "./views/dialog/Report";
import SendMessage from "./views/dialog/SendMessage";
import VideoReceive from "./views/dialog/VideoReceive";
import MessageDetail from "./views/dialog/MessageDetail";
import Follow from "./views/profile/Follow";
import Confirm from "./views/dialog/Confirm";
import VideoReview from "./views/dialog/VideoReview";
import VideoChat from "./views/video/VideoChat";
import vchat from "./lib/vchat";
import Gnb from "./components/Gnb";
import 'vue-loading-overlay/dist/css/index.css';
import Loading from 'vue-loading-overlay';

export default {
  name: 'App',
  components: {
    Gnb,
    HeaderBar,
    Contents,
    VideoProfile,
    Report,
    SendMessage,
    VideoReceive,
    MessageDetail,
    Follow,
    Confirm,
    VideoReview,
    VideoChat,
    Loading,
  },
  data() {
    return {
      // 액티브 페이지 인덱스
      activeTabIdx: 2,
      // 유저상세팝업
      showUserModal: false,
      userInfo: {},
      // 신고하기팝업
      showReportModal: false,
      reportInfo: {},
      // 메시지전송팝업
      showMessageSendModal: false,
      messageInfo: {},
      // 영상통화수신팝업
      showVideoReceiveModal: false,
      videoUserInfo: {},
      userStats: {},
      roomId: '',
      // 메시지상세팝업
      showMessageDetail: false,
      messageUser: {},
      // 콘펌팝업
      showConfirmModal: false,
      confirmTitle: '',
      confirmContent: '',
      confirmOk: '',
      confirmCancel: '',
      confirmCallback: null,
      // 비디오리뷰팝업
      showVideoReviewModal: false,
      reviewInfo: {},
      // 비디오채팅팝업
      showVideoChat: false,
      videoData: {},
      // 모바일 햄버거메뉴
      showHamMenu: false,
      showFollowPopup: false,
    }
  },
  computed: {
    isStartupStage() {
      return this.$route.name == 'Login' ||
        this.$route.name == 'PhoneVerify';
    },
    authenticate() {
      return this.$store.getters.isLogin &&
        this.$route.name != undefined &&
        this.$route.name != 'Login' &&
        this.$route.name != 'PhoneVerify';
    },
    myInfo() {
      return this.$store.getters.me;
    },
    settingInfo() {
      return this.$store.getters.setting;
    }
  },
  mounted() {
    this.emitter.on(EVENT_NAME.NEW_MESSAGE_TEXT, this.onNewMessageText);
    this.emitter.on(EVENT_NAME.NEW_MESSAGE_IMAGE, this.onNewMessageImage);
    this.emitter.on(EVENT_NAME.NEW_MESSAGE_VIDEO, this.onVideoReceive);
    this.emitter.on(EVENT_NAME.NEW_MESSAGE_GIFT, this.onGift);
    this.emitter.on(EVENT_NAME.VIDEO_CHAT, this.go2VideoChat);
    this.emitter.on(EVENT_NAME.VIDEO_RECEIVE, this.showVideoReceiveDlg);
    this.emitter.on(EVENT_NAME.VIDEO_REVIEW, this.showVideoReviewDlg);
    this.emitter.on(EVENT_NAME.REPORT, this.showReportDlg);
    this.emitter.on(EVENT_NAME.CLOSE_DIALOGS, this.closeDialogs);

    window.onpopstate = () => {
      if (this.authenticate) {
        let currentPath = this.$route.path;
        if (ROUTES.includes(currentPath)) {
          this.activeTabIdx = ROUTES.indexOf(currentPath);
          saveData('view_chat_tab', this.activeTabIdx);
        }
      }
    }

    setTimeout(() => {
      this.loadPointCashInfo();
      this.loadRefundVisibility();
    }, 1000);
    
  },
  beforeUnmount() {
    this.emitter.off(EVENT_NAME.NEW_MESSAGE_TEXT, this.onNewMessageText);
    this.emitter.off(EVENT_NAME.NEW_MESSAGE_IMAGE, this.onNewMessageImage);
    this.emitter.off(EVENT_NAME.NEW_MESSAGE_VIDEO, this.onVideoReceive);
    this.emitter.off(EVENT_NAME.NEW_MESSAGE_GIFT, this.onGift);
    this.emitter.off(EVENT_NAME.VIDEO_CHAT, this.go2VideoChat);
    this.emitter.off(EVENT_NAME.VIDEO_RECEIVE, this.showVideoReceiveDlg);
    this.emitter.off(EVENT_NAME.VIDEO_REVIEW, this.showVideoReviewDlg);
    this.emitter.off(EVENT_NAME.REPORT, this.showReportDlg);
    this.emitter.off(EVENT_NAME.CLOSE_DIALOGS, this.closeDialogs);
  },
  methods: {
    loadPointCashInfo() {
      if (this.myInfo != null) {
        let _app = this;
        let originalPoint = this.myInfo.points;
        let originalCash = this.myInfo.cash;
        requestPost(
          'user/getUserCash',
          {},
          response => {
            if (response.points != originalPoint || response.cash != originalCash) {
              _app.myInfo.points = response.points;
              _app.myInfo.cash = response.cash;
              _app.$store.dispatch('setMyInfo', _app.myInfo);
            }
          },
          (code, msg) => {
          }, 
          false
        );
      }
    },
    loadRefundVisibility() {
      if (this.myInfo != null) {
        let _app = this;
        let originalRefundVisible = this.myInfo.refund_visible;
        requestPost(
          'user/getUserRefundVisibility',
          {},
          response => {
            if (response.refund_visible != originalRefundVisible) {
              _app.myInfo.refund_visible = response.refund_visible;
              _app.$store.dispatch('setMyInfo', _app.myInfo);
            }
          },
          (code, msg) => {
          }, 
          false
        );
      }
    },
    currentTab() {
      return this.activeTabIdx;
    },
    onConfirmOk: function () {
      this.showConfirmModal = false;
      if (this.confirmCallback) {
        this.confirmCallback();
      }
    },
    onCharge: function () {
      this.showUserModal = false;
      this.$router.push('/charge');
    },
    onNewMessageText: function (packet) {
      if (packet.content == '###REMOVE_MESSAGE_CONTENT###') {
        return;
      }

      dao.saveUser(packet.fromUser, true);

      let readStatus = 1; // unread
      if (this.showMessageDetail) {
        if (this.messageUser.uid == packet.fromUser.userid) {
          readStatus = 0;
        }
      }

      if (readStatus == 1) {
        dao.insertMessage(packet.fromUser.userid, 0, packet.content, 'text', packet.timeStamp, readStatus);
        // showToast(`${packet.fromUser.nickname}: ${packet.content}`);
      }
    },
    onNewMessageImage: function (packet) {
      dao.saveUser(packet.fromUser, true);

      let readStatus = 1; // unread
      if (this.showMessageDetail) {
        if (this.messageUser.uid == packet.fromUser.userid) {
          readStatus = 0;
        }
      }

      if (readStatus == 1) {
        dao.insertMessage(packet.fromUser.userid, 0, packet.content, 'image', packet.timeStamp, readStatus);
        // showToast(`${packet.fromUser.nickname}: 이미지를 전송했습니다.`);
      }
    },
    onVideoReceive: function (packet) {
      if (!['refuse', 'busy', 'accept'].includes(packet.content)) {
        let _app = this;
        dao.saveUser(packet.fromUser, true);

        let readStatus = 1; // unread
        if (this.showMessageDetail) {
          if (this.messageUser.uid == packet.fromUser.userid) {
            readStatus = 0;
          }
        }
        dao.insertMessage(packet.fromUser.userid, 0, _app.$t('video.video_10'), 'video_request', packet.timeStamp, readStatus);

        if (this.myInfo.user_video_alarm != 0) {
          xmpp.sendDirectVideo(packet.fromUser, 'busy', true);
        } else {
          let videoLog = packet.content.split('_').filter(it => it != '');
          let nowTime = Date.now();
          let roomTime = Number(videoLog[1]);

          nowTime -= roomTime;
          if (nowTime < 60000) {
            requestPost(
              'user/getAverageVideoChatTimesAndReviewValues',
              {
                uid: this.myInfo.uid,
                peer_uid: packet.fromUser.userid
              },
              response => {
                _app.showVideoReceiveDlg({
                  userInfo: packet.fromUser,
                  userStats: response,
                  roomId: packet.content
                });
              },
              (code, msg) => {
                console.log('>>> video receive load stats fail due to ', msg);
              },
              false
            );
          }
        }
      }
    },
    onVideoAction: function (data) {
      this.showVideoReceiveModal = false;
      // go to video chat
      let tokens = data.roomId.split('/?r=');
      this.go2VideoChat({
        roomUrl: tokens[0],
        roomId: tokens[1],
        maxVideoTime: data.maxVideoTime,
        pointVideo: Math.abs(this.settingInfo.point_video_default),
        chattingWaitTime: parseInt(this.settingInfo.chatting_wait_time),
        freeChatTime: parseInt(data.freeChatTime),
        peerUser: fromXmppUserInfo(data.xmppUserInfo),
        fromUser: fromXmppUserInfo(data.xmppUserInfo),
      });
    },
    onGift: function (packet) {
      if (packet.subType == PacketSubType.GiftPoint) {
        let receivedPoints = Number(packet.content);
        let giftItemId = [100, 300, 500, 1000, 3000, 5000].indexOf(receivedPoints);
        if (giftItemId >= 0) {
          if (this.myInfo) {
            this.myInfo.points += receivedPoints;
            this.$store.dispatch('setMyInfo', this.myInfo);

            dao.saveUser(packet.fromUser, true);

            let readStatus = 1; // unread
            if (this.showMessageDetail) {
              if (this.messageUser.uid == packet.fromUser.userid) {
                readStatus = 0;
              }
            }
            dao.insertMessage(
              packet.fromUser.userid,
              0,
              `${receivedPoints}`,
              'gift_point_sent',
              Date.now(),
              readStatus
            );
          }
        }
      } else if (packet.subType == PacketSubType.GiftItem) {
        let itemId = Number(packet.content);
        let isVideoGift = itemId >= 0 && itemId <= 4;
        itemId = itemId % 10;

        if (itemId >= 0 && itemId <= 4) {
          let cash = Math.floor(VIDEO_CHAT_GIFT_ITEM_POINTS[itemId] * 0.4);
          if (this.myInfo.user_sex == 1) {
            this.myInfo.cash = Number(this.myInfo.cash) + cash;
            this.$store.dispatch('setMyInfo', this.myInfo);
          }

          dao.saveUser(packet.fromUser, true);

          let readStatus = 1; // unread
          if (this.showMessageDetail) {
            if (this.messageUser.uid == packet.fromUser.userid) {
              readStatus = 0;
            }
          }
          dao.insertMessage(
            packet.fromUser.userid,
            0,
            `${itemId}`,
            isVideoGift ? 'gift_item_sent_in_video' : 'gift_item_sent',
            Date.now(),
            readStatus
          );
        }
      }
    },
    onVideoReviewClose: function () {
      this.showVideoReviewModal = false;
      vchat.finish(this.reviewInfo);
    },
    onReport: function () {
      this.showReportModal = false;
      this.emitter.emit(EVENT_NAME.REPORT_SUCCESS);
    },
    go2VideoChat: function (data) {
      this.videoData = data;
      this.showVideoChat = true;
    },
    onTab: function (tabIdx) {
      if (this.showHamMenu) {
        this.showHamMenu = false;
      }

      if (tabIdx === 10) {
        this.showFollowPopup = true;
        return;
      }

      this.activeTabIdx = tabIdx;
      this.$router.push(ROUTES[tabIdx]);
      saveData('view_chat_tab', tabIdx);
    },
    closeDialogs: function () {
      this.showMessageDetail = false;
      this.showFollowPopup = false;
      this.showUserModal = false;
    },
    showConfirmDlg(title, content, ok, cancel, cb = null) {
      this.confirmTitle = title;
      this.confirmContent = content;
      this.confirmOk = ok;
      this.confirmCancel = cancel;
      this.confirmCallback = cb;
      this.showConfirmModal = true;
    },
    showUserDlg: function (user) {
      this.userInfo = user;
      this.showUserModal = true;
    },
    showReportDlg: function (info) {
      this.reportInfo = info;
      this.showReportModal = true;
    },
    showMessageSendDlg: function (info) {
      if (this.myInfo.send_message_enabled == 'N') {
        return;
      }
      this.messageInfo = info;
      this.showMessageSendModal = true;
    },
    showMessageDetailDlg: function (user) {
      this.messageUser = user;
      this.showMessageDetail = true;
    },
    showVideoReceiveDlg: function (data) {
      this.videoUserInfo = data.userInfo;
      this.userStats = data.userStats;
      this.roomId = data.roomId;
      this.showVideoReceiveModal = true;
    },
    showVideoReviewDlg: function (user) {
      this.reviewInfo = user;
      this.showVideoReviewModal = true;
    },
    takePhoto: function (cb) {
      let fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.multiple = false;
      fileInput.accept = 'image/png,image/jpg,image/jpeg';
      fileInput.style.display = 'none';
      fileInput.onchange = (e) => {
        if (e.target.files.length < 1) {
          return;
        }

        document.body.removeChild(fileInput);
        fileInput = null;

        let file = e.target.files[0];
        if (cb) {
          cb(file);
        }
      };

      document.body.appendChild(fileInput);
      fileInput.click();
    },
    takePhotos: function (cb) {
      let fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.multiple = true;
      fileInput.accept = 'image/png,image/jpg,image/jpeg';
      fileInput.style.display = 'none';
      fileInput.onchange = (e) => {
        if (e.target.files.length < 1) {
          return;
        }

        document.body.removeChild(fileInput);
        fileInput = null;

        if (cb) {
          cb(e.target.files);
        }
      };

      document.body.appendChild(fileInput);
      fileInput.click();
    },
  }
}
</script>

<style>
@font-face {
    font-family: 'my-font';
    src: url('~@/assets/font/NotoSans-Regular.ttf');
}

#app {
  font-family: my-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
