<template>
  <transition name="fade">
    <div v-if="show" class="pop_back" @click="$emit('close')">
      <div class="pop_alert" @click="$event.stopPropagation()">
        <a href="javascript:" @click="$emit('close')" class="close">
          <img src="../../assets/img/btn_close.png">
        </a>
        <div class="title" v-if="title != ''"><p>{{ title }}</p></div>
        <div class="message">
          {{ content }}
        </div>
        <a v-if="ok != ''" href="javascript:" class="btn_yes" @click="$emit('ok')">
          {{ ok }}
        </a>
        <a v-if="cancel != ''" href="javascript:" class="btn_not" @click="$emit('close')">
          {{ cancel }}
        </a>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped src="./Confirm.scss"></style>

<script>
export default {
  name: "ConfirmDlg",
  props: {
    title: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
    content: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
    ok: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
    cancel: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
    show: {
      type: Boolean,
      default: false
    },
  },
  emits: ['close', 'ok'],
  data() {
    return {
      backButtonRouteGuard: null,
    }
  },
  watch: {
    show(show) {
      if (show) {
        // dialog show
        document.addEventListener('keyup', this.onKeyPress);
        // back button
        this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
          this.$emit('close');
          next(false);
        });
      } else {
        // dialog hide
        document.removeEventListener('keyup', this.onKeyPress);
        // back button
        if (this.backButtonRouteGuard) {
          this.backButtonRouteGuard();
        }
      }
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        let me = document.querySelector('.pop_alert').parentElement;
        let activeDlgList = document.querySelectorAll('.pop_back');

        if (activeDlgList.item(activeDlgList.length - 1) == me) {
          this.$emit('close');
        }
      }
    },
  }
}
</script>
