export default {
  app_name: 'ViewChat',
  app_error: {
    title: 'Terjadi sebuah kesalahan teknis.',
    msg: 'Error sudah dilaporkan kepada tim. Silahkan tutup aplikasi dan buka kembali!',
    network: 'Terjadi error dalam jaringan komunikasi',
    other_device_login: 'User dengan nomor telepon yang sama sudah login di perangkat lain.',
    image_not_supported: 'Format gambar tidak terdukung...',
    upload_file_not_exist: "Tidak ada file yang terunggah",
  },
  common: {
    male_short: 'P',
    female_short: 'W',
    male: "Pria",
    female: "Wanita",
    age: "",
    preparing: "Mempersiapkan...",
    yes: "Iya",
    no: "Tidak",
    ok: "OK",
    cancel: "Batalkan",
    point: "Poin",
    cash: "Uang",
    bread: "Roti",
    close: "Tutup",
    delete: "Hapus",
    second: "d",
    send: "Kirim",
    create: "BUAT",
    submit: "Kirim",
    like: "Suka",
    jjim: "Favorit",
    message: "Pesan",
    hits: "populer",
    next: "Selanjutnya",
    name: "Nama",
    phone_number: "Nomor Telepon",
    years: "tahun",
    support_type: "point_system-error_payment-error_other",
    max: "MAKS",
    point_alert: "Poin tidak cukup!",
    bread_alert: "Roti tidak cukup!",
    done: "Selesai",
    top_title: "Ini adalah profil <b>{nickname}</b>",
    top_subtitle: "Kamu dapat menggunakan ViewChat untuk mengatur beberapa fungsi dan profil.",
  },
  msg: {
    not_implemented_yet: "Belum diimplementasi.",
    please_wait: "Mohon tunggu...",
    input_intro: "Pilih intro Anda",
    select_sex: "Pilih jenis kelamin Anda",
    select_age: "Pilih usia Anda",
    input_nickname: "Silahkan masukkan nickname",
    nickname_too_short: "Nickname tidak boleh kurang dari 2 huruf.",
    nickname_too_long: "Nickname tidak boleh lebih dari 5 huruf.",
    input_message: "Silahkan masukkan pesan Anda",
    sent_message: "Pesan berhasil dikirim",
    software_update: "Update Software",
    select_country: "Silahkan pilih negara",
    like_success: "Berhasil",
    please_select_photo: "Silahkan pilih foto",
    input_photo_content: "Komen Momen",
    zzalbe_registered: "Foto ter-register!",
    zzalbe_registered_with_point: "Mendapatkan {point} karena menggunggah foto.",
    zzalbe_success: "Foto akan terunggah setelah pemeriksaan.",
    zzalbe_comment: "Silahkan input komentar.",
    block_user: "blokir {nickname}",
    send_msg_disabled: "Jangan kirim pesan",
    video_request_cancel: "Batalkan otomatis setelah {second} detik",
    video_waiting_time_out: "Waktu permintaan video habis! Silahkan coba lagi.",
    check_in: "Berhasil check in. Jangan lupa check in besok",
    profile_saved: "Simpan info profil",
  },
  header_bar: {
    live: 'Live',
    recom: 'Rekomendasi',
    photo: 'Foto',
    moment: 'Momen',
    chat: 'Obrolan',
    profile: 'Profil',
    follow: 'Follow',
    charge: 'Isi Ulang',
    history: 'Riwayat',
    guide: 'Panduan',
    point: 'Poin:',
    agreement: "Perjanjian dan Kebijakan",
    jp_purchase_policy: 'Purchase Policy',
    usage: 'Persetujuan pengguna',
    privacy: 'Kebijakan privasi',
    ugc: 'Persetujuan UGC',
    youth_policy: 'Kebijakan pemuda',
    company_name: 'BC INNOVATION',
    boss: 'CEO : Hyunsuk Park',
    biz: 'Biz No : 227-81-20333',
    southwest: '305-1, 39 Cheongdae-ro 204beon-gil, Sokcho-si, Gangwon-do',
    tel: ' No.Telp : 1599-1401',
    email_prefix: 'E-Mail : hubungi',
    email_suffix: 'bcinnovation.co.kr',
    login: 'Login',
    logout: 'Keluar',
    logout_confirm: 'Apakah kamu yakin ingin keluar?',
    top_button: "Atas",
    viewchat_point: "Poin ViewChat",// TODO - translate
    view_more: "Lihat Lebih Banyak",// TODO - translate
    viewchat_live: "Lihat <b>Peringkat</b>",// TODO - translate
  },
  splash: {
    start_with_sns: 'Mulai dengan media sosial',
    view_chat_login: 'Masuk dengan Email',
    view_chat_signup: 'Daftar',
    forgot_password: 'Forgot password?',
  },
  reset_password: {
    title: 'Password Reset',
    button_title: 'Reset Password',
    completed: 'Password reset completed!',
  },
  login: {
    title: "ViewChat - Percakapan Video tatap muka",// TODO - translate
    login_01: "Selamat datang di ViewChat",// TODO - translate
    login_02: "Face chat yang menyenangkan, video call yang seru, ViewChat",// TODO - translate
    guide: 'Masukkan ID dan kata sandi dengan benar.',
    input_id: 'Masukkan ID',
    input_pwd: 'Masukkan kata sandi',
    auto_login: 'Masuk secara otomatis',
    login: 'MASUK',
  },
  phone: {
    verify: "Verifikasi",
    exit: "Keluar",
    str_adult_auth_title: "Title",
    str_adult_auth_text1_1: "Text1-1",
    str_adult_auth_text1_2: "Text1-2",
    str_adult_auth_text3_1: "Text3-1",
    str_adult_auth_text3_2: "Text3-2",
    str_adult_auth_text3_3: "Text3-3",
  },
  age_agreement: {
    title: 'Age agreement',
    text1: 'Are you older than 18?',
    text2: 'Only adults can use this service!',
    reject: 'Reject',
    agree: 'Agree'
  },
  signup: {
    only_10_sec: 'Hanya 10 detik,',
    sign_up: 'Silahkan daftar!',
    id_placeholder: 'ID (Email)',
    invalid_id: 'ID tidak tepat (Email)',
    pwd: 'Kata Sandi',
    pwd_confirm: 'Konfirmasi Kata Sandi',
    pwd_confirmed: 'Kata sandi terkonfirmasi!',
    agree_to_usage: 'Setuju dengan Persetujuan pengguna dan kebijakan privasi',
    select_profile: 'Pilih Foto Profil Anda',
    select_photo: 'Pilih Foto',
    nickname_placeholder: 'Masukkan nickname Anda (Maks 6 Karakter)',
    yrs: 'tahun',
    pay_attention: '*Mohon perhatikan, tidak dapat diubah setelah terdaftar!',
    start: 'MULAI',
    title: "Daftar",
    signup: "Gabung",
    msg_input_nickname: "Silahkan masukkan nickname!",
    msg_input_gender: "Silahkan pilih jenis kelamin!",
    msg_input_motto: "Silahkan pilih motto!",
    msg_input_age: "Silahkan pilih usia!",
    gender_warning: "Jenis kelamin tidak dapat diubah",
    msg_nickname_too_short: "Nickname tidak bisa kurang dari 2 huruf.",
    msg_nickname_too_long: "Nickname tidak bisa lebih dari 5 huruf.",
    send_cert_key: "Kirim Cert Key",
    verify_cert_key: "Verifikasi Cert Key",
    msg_input_email: "Silahkan masukkan alamat email!",
    msg_input_cert_key: "Silahkan masukkan cert key.",
    msg_sent_cert_key: "Cert key terkirim ke email Anda. Silahkan masukkan kode cert key ke kolom berikut..",
    cert_key_placeholder: "Cert Key",
    email_verified: "Email terverifikasi",
    msg_verify_email: "Silahkan verifikasi alamat email kamu!",
    msg_input_password: "Silahkan masukkan kata sandi!",
    msg_confirm_password: "Konfirmasi kata sandi!",
  },
  live: {
    title: "Selamat datang <b>{nickname}</b> di ViewChat Live",
    subtitle: "Kamu punya {message_count} pesan baru dan {notification_count} notifikasi baru.",
    all_country: "Semua Negara",
    more_country: "Lihat Lebih",
    real_time_hot_country: "Negara Populer Sekarang:",
    ranking: 'Peringkat',
    recent_login: 'Login Terbaru',
    video_chatting: "Berbincang",
    more: "Lebih",
  },
  recommend: {
    title: "ViewChat <b>Recommend</b> Pengguna",
    subtitle: "Aku adalah anggota real-time video chat yang direkomendasikan oleh ViewChat",
    recommend_today: 'Hari ini',
    recommend_recom: 'Rekomendasi',
    recommend_members: 'Pengguna',
    recommend_users: 'User terpilih oleh ViewChat AI',
    total_star: "Total",
    total_star_2: "",
    total_star_3: "",
    recv_star: "diterima!",
    album: "Album",
    jjim: "Keinginan",
    message: "Pesan",
    video: "Video",
    intro: "Pesan perkenalan",
    average_video_time: "Rata-Rata Durasi Video",
    video_time_below_15s: "< 15detik",
    video_time_below_1min: "< 1menit",
    video_time_below_3min: "< 3menit",
    video_time_above_3min: "> 3menit",
    average_review_point: "Rata-Rata Nilai Ulasan",
    favourite_factor: "Fav",
    belief_factor: "Beli",
    manner_factor: "Etika",
    beauty_factor: "Kecantikan",
    delete: "Hapus",
    msg: 'Pesan',
  },
  photo: {
    title: "Konten <b>Moment</b> ViewChat",
    subtitle: "Ini adalah momen seru dan menyenangkannya Viewchat.",
    category: "Semua_Profil",
    category_types: "all1_zzalbe_gif_recommend_me",
    create: "Buat",
    guide: "Foto-foto",
    hit: "Populer ",
    write_title: "Buat Momen",
    write_guide: "Tidak bisa membuat tanpa gambar.",
    write_desc: "Buat konten menarik sebagai momen Anda.",
    visit: "Kunjungan",
    recommend: "Rekomendasi",
    pick_photo: "Klik untuk register gambar",
    delete_success: "Penghapusan berhasil.",
    emoticon_delete: "Hapus emoji",
    emoticon_delete_confirm: "Ingin menghapus emoji?",
    emoticon_delete_success: "Emoji telah dihapus.",
    upload: "Unggah",
    bookmark: "Daftar Bacaan",
    like: "Suka",
    gift: "Hadiah",
    report: "Lapor",
    prev_contents: "Konten Sebelumnya",
    next_contents: "Konten Selanjutnya",
    content_hashtag: 'Content and Hashtag',
    hashtag: '# Hashtag',
    hashtag_hint: '* Selected hashtags are cleared when reselect.',
    public_options: 'Public Options',
    free: 'Free',
    followers: 'Followers',
    paid: 'Paid',
    paid_hint: '* Paid Guideline',
    hashtag_select: 'Hashtag Select',
    hashtag_select_hint: 'Up to 3 hashtags can be selected.',
    price_guideline: 'Price Guideline',
    price_guideline_photo_1: 'A photo: ',
    price_guideline_photo_2: '20 {price} flowers',
    price_guideline_video_1: 'A video: ',
    price_guideline_video_2: '60 {price} flowers',
    price_guideline_audio_1: 'An audio: ',
    price_guideline_audio_2: '30 {price} flowers',
    price_must_be_nonzero: 'Price must be non-zero.',
    price_must_below: 'Price must below {maxPrice}.',
  },
  video: {
    video_call_allow_necessary_permissions: "Berikan izin",
    video_01: "{nickname} membatalkan video call",
    video_02: "Konfirmasi pembatalan",
    video_03: "Kamu yakin ingin menyelesaikan video chat?",
    video_05: "Selesaikan video chat",
    video_07: "Poin tidak cukup untuk chatting!",
    video_08: "Selamat! Anda memperoleh {cash}.",
    video_09: "Video chat dihentikan karena poin {nickname} tidak cukup",
    video_10: "Meminta video chatting",
    video_11: "Poin tidak cukup",
    video_12: "Oh tidak! panggilan tidak terjawab. Silahkan tinggalkan pesan~ Pesan akan dibalas setelah dikonfirmasi.",
    video_14: "Lawan bicara sedang melakukan video chatting.",
    video_22: "Tolak",
    video_23: "Terima",
    video_24: "Permohonan video baru",
    video_25: "Permohonan video global baru",
    video_26: "(Tolak secara otomatis setelah {second} detik))",
    video_27: "Jenis kelamin sama! Silahkan kirim permintaan video ke anggota lawan jenis kelamin.",
    video_28: "webcam tidak tersambung.",
    video_profile_02: "{nickname} dimasukkan ke dalam daftar keinginan",
    global_user: "Pengguna Global",
    global_user_warning: "Ini adalah pengguna global.<br/>Memerlukan poin 2 kali lipat.",
    video_call_request: "Memohon Video Chatting...",
    video_waiting_time_out: "Oh tidak! Koneksi lawan bicara terputus. Silahkan coba kembali.",
    review_title: "Bagaimana obrolan video-nya?",
    review_select_rate: "Silahkan pilih '{review}' rate",
    review_select_rate_1: "Pilih di bawah berikut untuk mulai",
    review_select_rate_2: "<b>Nilai tidak terakumulasi.</b><br/>Silahkan nilai dengan benar.",
    review_skip: "Lewati",
    review_success: "Sudah ternilai.",
    video_call_received: 'Permohonan Obrolan Video',
    video_call_finished: 'Video chat finished {time}',
  },
  message: {
    message_09: "Hapus Semua",
    message_10: "Baca Semua",
    message_11: "Berikut pesan-pesannya.",
    message_read: "Baca",
    message_unread: "Belum dibaca",
    message_delete_title: "Hapus pesan",
    message_delete_confirm: "Apakah yakin ingin menghapus semua pesan?",
    message_read_title: "Baca pesan",
    message_read_confirm: "Apakah yakin ingin membaca semua pesan?",
    message_sent_photo: "Kirim foto",
    message_sent_gift: "Kirim hadiah",
    message_content_delete: "Hapus konten",
    message_content_delete_confirm: "Apakah kamu yakin ingin menghapus pesan-pesan?",
    message_content_placeholder: "Silahkan masukkan pesan",
    message_content_hint: "Masukkan pesan",
    msg_send_msg_disabled: "Pesan tidak bisa dikirim.",
    msg_block_user: "{nickname} terblokir",
    msg_like_success: "Direkomendasikan.",
    msg_sent_message: "Pesan berhasil terkirim",
    photo: "Foto",
    jjim: "Keinginan",
    block: "Blokir",
    report: "Lapor",
    message_input: "Please input message.",
    send_message: "Kirim pesan",
    send_desc: "{point}P akan ditarik.<br/>Pesan pemberitahuan di sini.",
    unread_messages: 'Pesan tak terbaca',
    viewchat_messages: 'Nikmati ViewChat <b>Message</b>',
    viewchat_messages_intro: 'Pesan ViewChat dibagikan kepada anggota lain. Selamat berbincang!',
    viewchat_messages_info: 'Ada sebanyak <b>{total_count}</b> pesan dan <b>{unread_count}</b> pesan tak terbaca.',
    warning_message: 'Dalam hal, pelanggaran Undang-undang Perlindungan Pemuda dan peraturan menurut masing-masing lembaga peninjau, pelanggar dapat dikenakan pembatasan penggunaan dan sanksi hukum.',
  },
  profile: {
    title: "Edit Profil",
    average_video_time: "Durasi Video Rata-Rata",
    average_review_point: "Tingkat Ulasan Rata-Rata",
    short_average_video_time: "DurVidRata",
    short_average_review_point: "TingUlasanRata",
    video_time_below_15s: "Kurang dari 15 detik",
    video_time_below_1min: "Kurang dari 1 menit",
    video_time_below_3min: "Kurang dari 3 menit",
    video_time_above_3min: "Lebih dari 3 menit",
    favourite_factor: "Favorit",
    belief_factor: "Benar",
    manner_factor: "Etika",
    beauty_factor: "Kecantikan",
    profile_intro: "Motto",
    profile_notify: "Notifikasi",
    profile_12: "Nickname",
    profile_13: "Silahkan masukkan 8",
    profile_14: "Usia",
    profile_15: "Jenis kelamin",
    profile_16: "(Tetap)",
    profile_23: "Simpan",
    profile_24: "Edit",
    profile_25: "Video Profil",
    profile_26: "Logo bawaan",
    dibs: "Pilihan pertama",
    recommend: "Rekomendasi",
    album: "Album",
    message: "Pesan",
    voice_chat: "Voice Chat",
    video_chat: "Video Chat",
    favorite: "Favorit",
    belive: "Belive",
    manner: "Etika",
    beauty: "Kecantikan",
    photos: "Foto",
  },
  album: {
    title: "Album",
    confirm: "Kamu yakin ingin menghapus?"
  },
  more: {
    more_09: "Isi ulang",
    more_10: "Gift Items",
    more_11: "Gifticon",
    more_13: "Riwayat",
    more_14: "Tanya",
    more_15: "Panduan",
    more_16: "Pemberitahuan",
    more_21: "Cek update",
    more_22: "Cek versi terbaru.",
    more_30: "Album",
    more_31: "Sedang dicek",
    more_32: "Umumkan",
    more_33: "Bagikan",
    more_34: "Peroleh poin setelah membagi.",
    more_35: "Edit profil",
    more_36: "Perhatikan cheet!",
    more_37: "Mohon untuk memperhatikan cheet.",
    more_38: "Pengguna",
    more_39: '50 Poin',
    more_40: 'Pengaturan Akun',
    more_41: 'Transfer',
    more_42: 'Notif verifikasi ON',
    more_43: 'Rekomendasi',
    more_44: 'Pengaturan Notifikasi',
    more_45: 'Ada yang mau jadi teman online?',
    more_46: 'Waspada Terhadap Penipuan Phishing Baru!',
    more_47: 'Curigai sebagai penipuan phishing ketika diminta beralih ke pesan atau aplikasi lain.',
    leave: 'Leave',
    leave_message: 'When you leave us, you will lose all of your point and cash. and 150 days later, you will be able to sign up again.\n\nAre you sure to leave now?',
    refund: 'Refund',
  },
  zzalbe: {
    message: "Pesan",
    gift: "Hadiah",
    delete: "Hapus",
    reply: "Balas",
    msg: "Pesan",
  },
  notify: {
    setting_video_receive: "Video",
    setting_video_receive_global: "Video Global",
    setting_sound: "Suara",
    setting_vibration: "Getar",
    setting_on: "ON",
    setting_off: "OFF",
  },
  log: {
    title: "Riwayat",
    log_list_02: "Riwayat Poin",
    log_list_03: "Riwayat Roti",
    point: "Poin: ",
    cash: "Roti: "
  },
  charge: {
    title: "Isi Ulang",
    charge_please: "Panduan Isi Ulang Langsung",
    direct_request: "Permintaan Langsung",
    desc1: "Akun : NewYorkBank  304101-04-349026",
    desc2: "Setelah memilih barang yang akan disetor, silahkan tinggalkan nama penyetor untuk menghubungi kami. Penyetoran akan diperiksa dan diproses.\n",
    by_app: "oleh aplikasi",
    open_app: "Buka Aplikasi",
    account_holder_name: "Nama",
    input_name: "(Silahkan masukkan nama.)",
    input_phone_number: "(Silahkan masukkan nomor telepon.)",
    direct_success: "berhasil",
    amount: "Jumlah:",
    charge_point: "Poin:",
    charge_items: "Silahkan<br/>isi ulang<br/>barang!",
    charge_direct: "Isi Ulang<br/>Langsung",
    charge_purchase: "beli",
    charge_discount: "Diskon",
    charge_item_price: "$",
    vip_item_price: "W",
    charge_item_point: "P",
    charge_vip_title: "Barang VIP",
    charge_vip_comment: "(Eksklusif untuk pengguna VIP)",
    charge_vip_unavailable: "tidak tersedia",
    charge_vip1_price: "50,000W",
    charge_vip2_price: "100,000W",
    charge_vip1_point: "35,000P",
    charge_vip2_point: "70,000P",
    charge_11: "Gratis",
    charge_12: "Gratis 1",
    charge_14: "Gratis 2",
    charge_15: "Gratis 3",
    charge_16: "Transfer",
    charge_17: "Uang > Poin",
    charge_18: "Poin > Uang",
    purchase_success: "Poin terkirimkan kepada {nickname} melalui isi ulang gratis.",
    paypal_purchase_rewarded: "{point} poin dibeli.",
    message_goto_app: 'Fitur ini hanya tersedia dalam aplikasi Supercam. Buka Supercam?',
    paypal_purchase_success: 'Pembayaran berhasil!\n Silahkan menunggu untuk pengiriman poin pembayaran.',
    select_item: 'Charge item',
    select_pg: 'Payment method',
    card: 'Card',
    tele_d: 'd Pay',
    tele_au: 'au mobile',
    tele_softbank: 'SoftBank',
    guide_title: 'Point Guide',
    guide_text1: '1. This service is basically free. However, you can use points to use some functions.',
    guide_text2: '2. Send DM, video call, or gift items can be purchased or used using points.<br/>Send DM - costs 15P each.<br/>Video call - 200P per minute required.<br/>Gift item - 10 ~ 900P is required depending on the item type.',
    item_select: 'Item',
    item4_hint: '3,000P おトク‼',
    item3_hint: '1,000P おトク‼',
    item2_hint: '¥ 1,000 おトク‼',
    item1_hint: '人気No.1',
    item0_hint: 'お試し価格',
  },
  gifticon: {
    gifticon_03: "Name",
    gifticon_04: "Harga",
    gifticon_05: "Telepon",
    gifticon_06: "Bayar",
    gifticon_07: "Panduan",
    gifticon_10: "Beli",
    gifticon_11: "Brand",
    gifticon_12: "Biaya",
    gifticon_13: "Berakhir",
    gifticon_14: "Periode kedaluwarsa ",
    gifticon_15: "Silahkan masukkan nomor telepon",
    gifticon_16: "jual gifticon",
    gifticon_02: "Pembelian telah diajukan. Ikon akan dikirimkan ke nomor telepon pengguna setelah proses dalam 24 jam.",
    request_gifticon: "Meminta gifticon",
    category_select: "Pilih kategori",
    category_1: "Barang",
    category_2: "Toko",
    category_3: "Kupon",
    category_4: "Makanan Cepat Saji",
    category_5: "Roti",
    category_6: "Kopi",
    category_7: "Restoran",
    category_8: "Film",
    category_9: "Kecantikan",
    category_10: "Wisata",
    category_11: "Digital",
    category_12: "Bayi",
  },
  refund: {
    refund_agree_10: "Pengembalian Dana",
    refund_agree_11: "Perhatian",
    refund_agree_12: "teks panduan",
    refund_agree_13: "Panduan",
    refund_agree_14: "teks panduan",
    refund_03: "Masukkan nama pemilik akun.",
    refund_04: "Masukkan nomor penduduk.",
    refund_05: "Masukkan nomor telepon.",
    refund_06: "Masukkan alamat.",
    refund_08: "Masukkan nomor akun.",
    refund_10: "minimum 30,000 uang.",
    refund_11: "Uang tidak mencukupi.",
    refund_12: "Pengembalian dana berhasil.",
    refund_13: "Uang Saya",
    refund_14: "Pemilik Akun",
    refund_15: "Nomor Telepon",
    refund_16: "Nomor Penduduk",
    refund_18: "Alamat",
    refund_19: "Bank",
    refund_20: "Nomor rekening",
    refund_21: "Masukkan nomor rekening",
    refund_22: "Pengembalian dana - (minimum 30,000 uang)",
    refund_27: "Pengembalian uang",
    refund_28: "Permohonan Pengembalian Dana",
    refund_29: "Pilih bank",
    refund_30: "(Bonus",
    refund_31: "ditambahkan)",
    refund_bank: "bank1_bank2_bank3_bank4",
    owner: "Masukkan nama penabung",
    phone_desc: "Isi nomor telepon hanya dengan angka",
    resident_desc: "(Isi nomor telepon tanpa tanda hyphen(-))",
    address_desc: "Masukkan alamat",
    cash_desc: "Masukkan cache register (Isi dalam jumlah 10.000)",
    top_hint: 'Top hint',
    middle_hint: 'Middle hint',
    bottom_hint: 'Bottom hint',
  },
  category: {
    category_1: "Barang",
    category_2: "Toko",
    category_3: "Kupon",
    category_4: "Makanan Cepat Saji",
    category_5: "Roti",
    category_6: "Kopi",
    category_7: "Restoran",
    category_8: "Film",
    category_9: "Kecantikan",
    category_10: "Wisata",
    category_11: "Digital",
    category_12: "Bayi",
  },
  transfer: {
    title: "Transfer",
    bread: "Roti: ",
    point2bread: "Poin to Roti",
    bread2point: "Roti to Poin",
  },
  ask: {
    ask_email: "Email",
    ask: "Tanya",
    desc: "Silahkan masukkan dengan tepat",
    asked: "Ditanyakan",
    asked_message1: "ㆍ Pesan1.",
    asked_message2: "ㆍ Pesan2.",
    asked_message3: "ㆍ Pesan3.",
    ok: "OK",
    ask_category: "Kategori",
    ask_content: "Konten",
    ask_01: "Masukkan alamat email.",
    ask_02: "Pilih kategori",
    ask_03: "Masukkan konten tanya.",
    ask_04: "Silahkan pilih foto bukti pembayaran yang gagal.",
    ask_05: "Tidak ada jawaban...",
    ask_06: "Tanya",
    ask_08: "Berhasil bertanya",
    ask_09: "Masukkan alamat email.",
    ask_11: "Unggah Gambar: ",
    ask_12: "* Teks perhatian...",
    ask_13: "Cek Jawaban",
    ask_14: "Keluar",
    ask_15: " ",
    agree: "Setuju.",
    agree_content: "Pertanyaan dan jawaban dikirimkan melalui e-mail dan e-mail hanya dikumpulkan untuk balasan.",
    upload_desc: "Konten, gambar, dan kalimat tidak senonoh yang tidak ada korelasi dengan pertanyaan tidak akan dijawab."
  },
  announce: {
    title: "Pengumuman",
  },
  report: {
    title: "Lapor",
    warning: "Pilih alasan",
    reason: "Anomali Jenis Kelamin_Tindakan Seksual _Promosi Pertemuan Bersyarat_Iklan_Lain-Lain",
    placeholder: 'Lapor kategori',
    please_select_category: 'Silahkan pilih kategori pelaporan.',
    success: "Lapor berhasil",
  },
  notify_setting: {
    title: "Pengaturan Notifikasi",
    video_notify: "Notifikasi Video",
    video_notify_global: "Notifikasi Video Global",
    video_notify_sound: "Notifikasi Video Suara",
    video_notify_vibrate: "Notifikasi Video Getar",
  },
  country: {
    title: "Semua Negara",
    search: "Cari",
    real_country: "Waktu negara nyata",
    rank: "PERINGKAT",
    more_country: "Lihat Negara Lain",
    max_country: "Maksimal 5 negara.",
    hot: "Populer",
    alpha: "alfabet",
    thail: "Thail",
    viet: "Viet",
    rus: "Rus",
    real_coun: "Waktu Negara Asli",
    country1: "Thailand",
    country2: "Ukraina",
    country3: "Rusia",
    country4: "Amerika Serikat",
    country5: "Taiwan",
    country6: "AAA",
    warning1: "Hanya satu negara",
    country_code_list: "tha_uga_rus_usa_twn_alb",
    country_list: "Thailand_Uganda_Russian_United States_Taiwan_AAAA",
    all_country: "Semua Negara",
    apply: "Aplikasikan",
    turn_off_global: "Matikan global",
  },
  guide: {
    title: "Panduan",
    usage_agreement: "Persetujuan pengguna",
    privacy_policy: "Kebijakan privasi",
  },
  gift_items: {
    title: 'Gift Items',
    buy: 'Buy',
    fingerheart: 'FingerHeart',
    flowers: 'Flowers',
    ring: 'Ring',
    palace: 'Palace',
    megaheart: 'MegaHeart',
    gift_item_names: ['FingerHeart', 'Flowers', 'Ring', 'Palace', 'MegaHeart'],
  },
  gift_item_purchase: {
    title: 'Gift Item Purchase',
    amount: "Jumlah:",
    price: "Poin:",
  },
  follow: {
    follower: 'Follower',
    following: 'Following',
    block: 'Block',
  },
  album: {
    profile_edit: 'Profile Edit',
    write: 'Write',
    posts: 'Posts',
    likes: 'Likes',
    followers: 'Followers',
    followings: 'Followings',
    motto_hint: 'Input motto.',
    input_motto: 'Please input motto!',
    save: 'Save',
    video_call: 'Video Call',
    send_message: 'Send Message',
    follow: 'Follow',
    unfollow: 'Unfollow',
    must_follow_each_other_text1: 'You are not followed each other!',
    must_follow_each_other_text2: 'Send message in order to follow each other.',
    no_photos: 'There are no photos.',
  },
  story_purchase: {
    title: 'Apakah ingin membeli\nkonten ini?',
    description1: 'Konten yang dibeli hanya dapat disimpan di dalam aplikasi.',
    description2:
      'Penyimpanan dan pembocoran dilarang, jika terjadi pelanggaran tanggung jawab sepenuhnya ada pada pengguna.',
    item_not_enough: 'Barang tidak cukup.',
    purchase_confirm: 'Konfirmasi Pembelian?',
    purchase_success: 'Pembelian selesai.',
  },
};
