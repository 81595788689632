export default {
  app_name: 'ViewChat',
  app_error: {
    title: '发生意外错误',
    msg: ',已发送错误报告，请重新执行。',
    network: '网络连接发生错误',
    other_device_login: '此号已注册',
    image_not_supported: '暂不支持此格式',
    upload_file_not_exist: ",此文件不存在",
  },
  common: {
    male_short: 'M',
    female_short: 'F',
    male: "男",
    female: "女",
    age: "",
    preparing: "准备中...",
    yes: "Yes",
    no: "No",
    ok: "OK",
    cancel: "取消",
    point: "积分",
    cash: "余额",
    bread: "面包",
    close: "关闭",
    delete: "删除",
    second: "秒",
    send: "发送",
    create: "创建",
    submit: "提交",
    like: "喜欢",
    jjim: "点赞",
    message: "消息",
    hits: "点击次数",
    next: "下一步",
    name: "名字",
    phone_number: "电话",
    years: "岁",
    support_type: "发生意外错误，支付错误",
    max: "最大",
    point_alert: "积分不足",
    bread_alert: "面包不足",
    done: "完成",
    top_title: "<b>{nickname}</b>'s 头像",
    top_subtitle: "填写个人资料和设置头像后，可使用ViewChat",
  },
  msg: {
    not_implemented_yet: "服务正在准备中",
    please_wait: "稍等片刻",
    input_intro: "选择添加介绍",
    select_sex: "选择性别",
    select_age: "选择年龄",
    input_nickname: "请输入昵称",
    nickname_too_short: "昵称设定不能低于2 个字",
    nickname_too_long: "请把昵称设定在5 个字以内",
    input_message: "输入消息",
    sent_message: "消息已发送",
    software_update: "软件升级",
    select_country: "选择国家",
    like_success: "已推荐",
    please_select_photo: "选择图片",
    input_photo_content: "请编辑文案",
    zzalbe_registered: "图片上传成功",
    zzalbe_registered_with_point: "{point} 获得积分",
    zzalbe_success: "审核通过后图片可显示",
    zzalbe_comment: "请输入评论",
    block_user: "{nickname} 拉黑",
    send_msg_disabled: "消息发送失败",
    video_request_cancel: "{second} 秒后自动取消",
    video_waiting_time_out: "上传视频已超时，请稍后再试。",
    check_in: "打卡成功。明天不要忘记来打卡哟。",
    profile_saved: "保存头像",
  },
  header_bar: {
    live: '动态',
    recom: '推荐',
    photo: '图片',
    moment: '热门推荐',
    chat: '私信',
    profile: '头像',
    follow: '关注列表',
    charge: '充值',
    history: '我的',
    guide: '新手指南',
    point: '积分:',
    agreement: "规则与政策",
    jp_purchase_policy: '特商法',
    usage: '使用规则',
    privacy: '个人信息保护政策',
    ugc: '用户协议',
    youth_policy: '青少年保护政策',
    company_name: 'BC INNOVATION',
    boss: 'CEO : Hyunsuk Park',
    biz: '营业执照 : 227-81-20333',
    southwest: '305-1, 39 Cheongdae-ro 204beon-gil, Sokcho-si, Gangwon-do',
    tel: ' TEL : 1599-1401',
    email_prefix: 'E-Mail : contact',
    email_suffix: 'bcinnovation.co.kr',
    login: '登录',
    logout: '退出登录',
    logout_confirm: '确定退出当前帐号吗？',
    top_button: "顶部",
    viewchat_point: "view chat 积分",
    view_more: "继续浏览",
    viewchat_live: "直播",
  },
  splash: {
    start_with_sns: '使用社交软件登录',
    view_chat_login: '登录',
    view_chat_signup: '注册会员',
    forgot_password: '找回密码',
  },
  reset_password: {
    title: '重新设置密码',
    button_title: '输入新密码',
    completed: '密码设置成功',
  },
  login: {
    title: "viewchat，可以视频通话的聊天软件",
    login_01: "欢迎使用viewchat",
    login_02: "享受愉快的视频聊天时间，尽在 view chat",
    guide: '请输入正确的账号和密码',
    input_id: '输入账号',
    input_pwd: '输入密码',
    auto_login: '自动登录',
    login: '登录',
  },
  phone: {
    verify: "确认",
    exit: "退出",
    str_adult_auth_title: "Title",
    str_adult_auth_text1_1: "Text1-1",
    str_adult_auth_text1_2: "Text1-2",
    str_adult_auth_text3_1: "Text3-1",
    str_adult_auth_text3_2: "Text3-2",
    str_adult_auth_text3_3: "Text3-3",
  },
  age_agreement: {
    title: '年龄限制',
    text1: '是否已成年？',
    text2: '未成年禁止使用此软件',
    reject: '否',
    agree: '是'
  },
  signup: {
    only_10_sec: '请在10 秒以内注册会员,',
    sign_up: '请注册',
    id_placeholder: '账号 (请使用邮箱注册)',
    invalid_id: '账号格式错误. (请使用邮箱注册)',
    pwd: '密码',
    pwd_confirm: '确认密码',
    pwd_confirmed: '密码设置成功',
    agree_to_usage: '同意使用规则及个人情报信息收集',
    select_profile: '选择头像',
    select_photo: '选择图片',
    nickname_placeholder: '请输入昵称（最多不超过6 个字',
    yrs: '岁',
    pay_attention: '* 注册后不可变更',
    start: '开始',
    title: "注册",
    signup: "注册",
    msg_input_nickname: "请输入昵称",
    msg_input_gender: "请选择性别",
    msg_input_motto: "请选择感兴趣的内容",
    msg_input_age: "请选择年龄",
    gender_warning: "性别选择后不可变更",
    msg_nickname_too_short: "昵称不能低于2 个字",
    msg_nickname_too_long: "昵称不能超出5 个字",
    send_cert_key: "发送验证码",
    verify_cert_key: "确认验证码",
    msg_input_email: "请使用邮箱注册",
    msg_input_cert_key: "请输入验证码",
    msg_sent_cert_key: "验证码已发送至邮箱，请输入验证码",
    cert_key_placeholder: "验证码",
    email_verified: "邮箱验证成功",
    msg_verify_email: "请使用邮箱注册",
    msg_input_password: "请输入密码",
    msg_confirm_password: "请再次确认密码",
  },
  live: {
    title: "欢迎 {nickname} 来到 viewchat 直播间</b>",
    subtitle: "有{message_count}个新消息和有{notification_count}个新通知",
    all_country: "全部国家",
    more_country: "再展开",
    real_time_hot_country: "实时人气国家:",
    ranking: '人气榜',
    recent_login: '活跃榜',
    video_chatting: "对方忙线中",
    more: "继续浏览",
  },
  recommend: {
    title: "viewchat 推荐会员",
    subtitle: "viewchat 推荐的视频通话优质会员",
    recommend_today: '今天',
    recommend_recom: '推荐',
    recommend_members: '会员',
    recommend_users: "此用户是view chat 推荐的优质会员",
    total_star: "全部",
    total_star_2: "",
    total_star_3: "",
    recv_star: "接收!",
    album: "相册",
    jjim: "点赞",
    message: "发私信",
    video: "邀请视频通话",
    intro: "介绍一下吧",
    average_video_time: "平均视频时间",
    video_time_below_15s: "不足15 秒",
    video_time_below_1min: "不足1 分钟",
    video_time_below_3min: "不足3 分钟",
    video_time_above_3min: "3 分钟以上",
    average_review_point: "平均视频分数",
    favourite_factor: "好感度",
    belief_factor: "可信度",
    manner_factor: "魅力值",
    beauty_factor: "颜值",
    delete: "删除",
    msg: '消息',
  },
  photo: {
    title: "ViewChat <b>Moment</b> Contents",
    subtitle: "享受view chat 美好时光",
    category: "全部头像",
    category_types: "all1_zzalbe_gif_recommend_me",
    create: "发动态",
    guide: "这里是相册",
    hit: "点击数 ",
    write_title: "打招呼",
    write_guide: "没有图片无法制作",
    write_desc: "开始聊天",
    visit: "访客数",
    recommend: "推荐",
    pick_photo: "请点击上传照片",
    delete_success: "已删除",
    emoticon_delete: "删除表情包",
    emoticon_delete_confirm: "确认删除吗？",
    emoticon_delete_success: "删除",
    upload: "上传",
    bookmark: "书签",
    like: "喜欢",
    gift: "礼物",
    report: "举报",
    prev_contents: "上一页",
    next_contents: "下一页",
    content_hashtag: '内容和标签',
    hashtag: '#标签',
    hashtag_hint: '* 重新选择时，所选标签将会解除。.',
    public_options: '公开选项',
    free: '免费',
    followers: '关注(免费)',
    paid: '关注(有偿)',
    paid_hint: '* 收费标准',
    hashtag_select: '选择 标签',
    hashtag_select_hint: '可选择3个标签',
    price_guideline: '收费标准',
    price_guideline_photo_1: '1张照片: ',
    price_guideline_photo_2: '20 {price} 花束',
    price_guideline_video_1: '1个视频: ',
    price_guideline_video_2: '60 {price} 花束',
    price_guideline_audio_1: '一条语音: ',
    price_guideline_audio_2: '30 {price} 花束',
    price_must_be_nonzero: '价格不能为0.',
    price_must_below: '最大价格｛maxPrice｝.',
    public_to_followers: '公开我的关注',
  },
  video: {
    video_call_allow_necessary_permissions: "解除访问权限",
    video_01: "已取消和 {nickname} 的视频通话",
    video_02: "确定结束",
    video_03: "确定结束视频通话吗？",
    video_05: "请结束视频通话",
    video_07: "积分不足",
    video_08: "恭喜，与 {cash} 配对成功",
    video_09: "{nickname} 积分不足，已终止聊天。",
    video_10: "邀请视频通话",
    video_11: "积分不足",
    video_12: "对方离线，请稍后再试",
    video_14: "对方离线，请稍后再试",
    video_22: "残忍拒绝",
    video_23: "同意",
    video_24: "新的视频聊天请求",
    video_25: "新的全球视频聊天请求",
    video_26: "({second}秒后自动拒绝)",
    video_27: "请邀请异性",
    video_28: "网页版无法使用摄像头",
    video_profile_02: "已把{nickname}添加到特别关注",
    global_user: "全球用户",
    global_user_warning: "对方是全球用户，需要消耗双倍积分",
    video_call_request: "邀请视频通话...",
    video_waiting_time_out: "已超时，请稍后再试",
    review_title: "视频通话感觉如何？",
    review_select_rate: "'{review}' 请评价",
    review_select_rate_1: "请在下列选项中选择",
    review_select_rate_2: "<b>评价发生错误</b><br/>请重新输入",
    review_skip: "跳过",
    review_success: "已评价",
    video_call_received: '邀请视频通话',
    video_call_finished: '视频通话已结束 {time}',
  },
  message: {
    message_09: "全部删除",
    message_10: "全部已读",
    message_11: "这里有新消息",
    message_read: "已读",
    message_unread: "未读",
    message_delete_title: "删除信息",
    message_delete_confirm: "确认删除全部信息吗？",
    message_read_title: "标为已读",
    message_read_confirm: "全部标为已读吗？",
    message_sent_photo: "发送图片",
    message_sent_gift: "送礼物",
    message_content_delete: "删除产品",
    message_content_delete_confirm: "确认删除此消息吗？",
    message_content_placeholder: "请输入消息",
    message_content_hint: "输入消息",
    msg_send_msg_disabled: "无法发送",
    msg_block_user: "已被{nickname}拉黑",
    msg_like_success: "已推荐",
    msg_sent_message: "消息成功发送",
    photo: "照片",
    jjim: "点赞",
    block: "拉黑",
    report: "举报",
    message_input: "请输入消息",
    send_message: "发送消息",
    send_desc: "已向{point}p 发送请求",
    unread_messages: '未读消息',
    viewchat_messages: '享受viewchat <b>的瞬间私信</b>',
    viewchat_messages_intro: '此时此刻，想和大家分享什么（此消息仅会员可见）',
    viewchat_messages_info: '共 <b>{total_count}</b>条消息 <b>包含{unread_count}</b>条未读消息.',
    warning_message: '违反青少年保护法和法律条约会受到使用限制或法律制裁',
  },
  profile: {
    title: "编辑头像",
    average_video_time: "平均视频时间",
    average_review_point: "平均评价积分",
    short_average_video_time: "平均视频时间",
    short_average_review_point: "平均评价积分",
    video_time_below_15s: "15 秒以下",
    video_time_below_1min: "1 分钟以下",
    video_time_below_3min: "3 分钟以下",
    video_time_above_3min: "3 分钟以上",
    favourite_factor: "好感度",
    belief_factor: "可信度",
    manner_factor: "魅力值",
    beauty_factor: "颜值度",
    profile_intro: "添加介绍",
    profile_notify: "通知",
    profile_12: "昵称",
    profile_13: "请输入8 位数以内",
    profile_14: "年龄",
    profile_15: "性别",
    profile_16: "(无法更改)",
    profile_23: "保存",
    profile_24: "修改",
    profile_25: "视频头像",
    profile_26: "默认头像",
    dibs: "点赞",
    recommend: "推荐",
    album: "相册",
    message: "发私信",
    voice_chat: "语音通话",
    video_chat: "视频通话",
    favorite: "好感度",
    belive: "可信度",
    manner: "魅力值",
    beauty: "颜度",
    photos: "图片",
  },
  album: {
    title: "相册",
    confirm: "确认删除?"
  },
  more: {
    more_09: "充值",
    more_10: "礼物商城",
    more_11: "优惠券",
    more_13: "我的",
    more_14: "客服",
    more_15: "新手指南",
    more_16: "官方通知",
    more_21: "确认版本",
    more_22: "确认是最新版本",
    more_30: "我的相册",
    more_31: "打卡",
    more_32: "官方通知",
    more_33: "转发",
    more_34: "转发可以获得积分",
    more_35: "修改头像",
    more_36: "警惕！",
    more_37: "警惕电信诈骗",
    more_38: "会员",
    more_39: '50 积分',
    more_40: '设置账号',
    more_41: '转换',
    more_42: '视频通知 ON',
    more_43: '推荐',
    more_44: '消息通知',
    more_45: '交个朋友吗?',
    more_46: '警惕新型电信诈骗',
    more_47: '诱导使用其他网站或小程序时，警惕存在电信诈骗.',
    leave: '注销账号',
    leave_message: '注销账号时，所拥有的积分和内容将会全部清零，注销150 天后可以重新注册，确定注销账号吗',
    refund: '提现',
  },
  zzalbe: {
    message: "消息",
    gift: "礼物",
    delete: "删除",
    reply: "回复",
    msg: "Msg",
  },
  notify: {
    setting_video_receive: "视频通知",
    setting_video_receive_global: "全球视频通知",
    setting_sound: "响铃",
    setting_vibration: "震动",
    setting_on: "ON",
    setting_off: "OFF",
  },
  log: {
    title: "我的",
    log_list_02: "我的积分",
    log_list_03: "我的余额",
    point: "积分: ",
    cash: "余额: "
  },
  charge: {
    title: "充值",
    charge_please: "充值使用规则",
    direct_request: "直接输入",
    desc1: "Account : NewYorkBank  304101-04-349026",
    desc2: "请先选择支付方式并留下打款人姓名后，客服会进行处理",
    by_app: "by App",
    open_app: "打开app",
    account_holder_name: "打款人",
    input_name: "(请输入打款人姓名)",
    input_phone_number: "(请输入电话号码)",
    direct_success: "申请成功",
    amount: "金额:",
    charge_point: "积分:",
    charge_items: "充值礼物商城",
    charge_direct: "直接充值",
    charge_purchase: "购买",
    charge_discount: "折扣",
    charge_item_price: "$",
    vip_item_price: "W",
    charge_item_point: "P",
    charge_vip_title: "VIP 权限",
    charge_vip_comment: "(VIP 特权)",
    charge_vip_unavailable: "无法使用",
    charge_vip1_price: "50,000W",
    charge_vip2_price: "100,000W",
    charge_vip1_point: "35,000P",
    charge_vip2_point: "70,000P",
    charge_11: "免费充值处",
    charge_12: "免费充值处1",
    charge_14: "免费充值处2",
    charge_15: "免费充值处3",
    charge_16: "转换",
    charge_17: "余额 > 积分",
    charge_18: "积分 > 余额",
    purchase_success: "已用积分充值",
    paypal_purchase_rewarded: "{point} 积分兑换成功",
    message_goto_app: '摄像头仅允许在app 中使用。请打开app 使用摄像头',
    paypal_purchase_success: '购买成功。积分到账之前请耐心等候。',    
    guide_title: '积分使用规则',
    guide_text1: '1.本产品免费使用，但是部分产品需要花费积分进行使用',
    guide_text2: '2. 可以通过购买积分使用‘发送DM’，‘视频通话’，‘礼物商城’等功能<br/> 发送DM--DM/个，需要15 积分<br/>视频通话--视频通话/分，需要200 积分<br/>礼物商城—根据礼物种类需要10~900 积分不等',
    item_select: '商城',
  },
  gifticon: {
    gifticon_03: "名字",
    gifticon_04: "价格",
    gifticon_05: "电话号码",
    gifticon_06: "购买",
    gifticon_07: "新手指南",
    gifticon_10: "购买",
    gifticon_11: "品牌",
    gifticon_12: "手续费",
    gifticon_13: "截止日期",
    gifticon_14: "截止日期 ",
    gifticon_15: "请输入电话号码",
    gifticon_16: "购买礼金券",
    gifticon_02: "申请成功",
    request_gifticon: "申请礼金券",
    category_select: "请选择种类",
    category_1: "商品",
    category_2: "商店",
    category_3: "优惠券",
    category_4: "快餐",
    category_5: "面包",
    category_6: "咖啡",
    category_7: "饭店",
    category_8: "图片",
    category_9: "美容",
    category_10: "旅行",
    category_11: "数字化",
    category_12: "小孩",
  },
  refund: {
    refund_agree_10: "申请退款",
    refund_agree_11: "注意事项",
    refund_agree_12: "提示语",
    refund_agree_13: "新手指南",
    refund_agree_14: "提示语",
    refund_03: "请输入注册人姓名",
    refund_04: "请输入身份证号",
    refund_05: "请输入电话号码",
    refund_06: "请输入地址",
    refund_08: "请输入银行卡号",
    refund_10: "30000 以上可以提现",
    refund_11: "余额不足",
    refund_12: "提现成功",
    refund_13: "我的余额",
    refund_14: "注册人",
    refund_15: "电话号码",
    refund_16: "身份证号",
    refund_18: "地址",
    refund_19: "银行",
    refund_20: "银行卡号",
    refund_21: "请输入银行卡号",
    refund_22: "提现 - (30,000 以上可以提现)",
    refund_27: "提现金额",
    refund_28: "申请提现",
    refund_29: "选择银行",
    refund_30: "(赏金",
    refund_31: "追加)",
    refund_bank: "bank1_bank2_bank3_bank4",
    owner: "输入收款方姓名",
    phone_desc: "电话号码只输入数字",
    resident_desc: "不支持输入符号“-”请只输入数字",
    address_desc: "请输入地址",
    cash_desc: "请输入提现金额（以万元为单位)",
    top_hint: '当日振込をご希望の場合は20時までに振込申請をしていただければ当日振込可能です。',
    middle_hint: '入金申請したキャッシュ-（1万キャッシュ以上から申請可能です）',
    bottom_hint: '【振込手数料】恐れ入りますが振込手数料はお客様の負担でお願いいたします。',
  },
  category: {
    category_1: "商品",
    category_2: "商店",
    category_3: "优惠券",
    category_4: "垃圾食品",
    category_5: "面包",
    category_6: "咖啡",
    category_7: "饭店",
    category_8: "电影",
    category_9: "美容",
    category_10: "旅行",
    category_11: "数字化",
    category_12: "育儿",
  },
  transfer: {
    title: "转换",
    bread: "面包: ",
    point2bread: "用积分兑换面包",
    bread2point: "用面包兑换积分",
  },
  ask: {
    ask_email: "邮箱",
    ask: "咨询",
    desc: "请输入正确问题",
    asked: "问题已提交",
    asked_message1: "ㆍ Message1.",
    asked_message2: "ㆍ Message2.",
    asked_message3: "ㆍ Message3.",
    ok: "确认",
    ask_category: "分类",
    ask_content: "内容",
    ask_01: "请输入邮箱地址",
    ask_02: "请选择类别",
    ask_03: "请输入问题",
    ask_04: "请选择发票图片",
    ask_05: "No answer...",
    ask_06: "咨询",
    ask_08: "问题已提交.",
    ask_09: "请输入邮箱地址",
    ask_11: "上传图片: ",
    ask_12: "* 注意事项...",
    ask_13: "确认答复",
    ask_14: "注销账号",
    ask_15: " ",
    agree: "同意下列条款",
    agree_content: "问答通过邮箱进行，收集邮箱的目的仅用作问题答疑。",
    upload_desc: "自动忽略与查询无关的内容，图片，方言"
  },
  announce: {
    title: "通知",
  },
  report: {
    title: "举报",
    warning: "请选择举报理由",
    reason: "内容令人不适-性骚扰-色情低俗-垃圾信息-其他内容",
    placeholder: '举报原因',
    please_select_category: '请选择举报理由',
    success: "举报成功.",
  },
  notify_setting: {
    title: "消息通知",
    video_notify: "视频通知",
    video_notify_global: "全球视频通知",
    video_notify_sound: "响铃通知",
    video_notify_vibrate: "震动通知",
  },
  country: {
    title: "所有国家",
    search: "查找",
    real_country: "人气国家",
    rank: "顺序",
    more_country: "再展开",
    max_country: "最多5 个国家",
    hot: "按人气顺序",
    alpha: "按字母顺序",
    thail: "泰国",
    viet: "越南",
    rus: "俄罗斯",
    real_coun: "热榜",
    country1: "泰国",
    country2: "Uqurain",
    country3: "俄罗斯",
    country4: "美国",
    country5: "台湾",
    country6: "AAA",
    warning1: "只能选择一个国家",
    country_code_list: "tha_uga_rus_usa_twn_alb",
    country_list: "Thailand_Uganda_Russian_United States_Taiwan_AAAA",
    all_country: "全部国家",
    apply: "确定",
    turn_off_global: "解除全球模式",
  },
  guide: {
    title: "新手指南",
    usage_agreement: "使用规则",
    privacy_policy: "个人情报保护政策",
  },
  gift_items: {
    title: '礼物商城',
    buy: '购买',
    fingerheart: '手势比心',
    flowers: '花束',
    ring: '钻戒',
    palace: '城堡',
    megaheart: '百万爱心',
    gift_ttem_names: ['手势比心', '花束', '钻戒', '城堡', '百万爱心'],
  },
  gift_item_purchase: {
    title: '购买礼物',
    amount: "余额:",
    price: "积分:",
  },
  follow: {
    follower: '粉丝',
    following: '关注',
    block: '拉黑',
  },
  album: {
    profile_edit: '修改头像',
    write: '发动态',
    posts: '动态',
    likes: '喜欢',
    followers: '粉丝',
    followings: '关注',
    motto_hint: '介绍一下自己吧',
    input_motto: '请介绍一下自己',
    save: '保存',
    video_call: '邀请视频通话',
    send_message: '发私信',
    follow: '关注',
    unfollow: '取关',
    must_follow_each_other_text1: ',他（她）还没有发表动态',
    must_follow_each_other_text2: '邀请他（她）发表动态',
    no_photos: '他（她）还没有发表动态',
  },
  story_purchase: {
    title: '你会购买\n这件商品吗？',
    description1: '购买的故事物品只能在应用内使用。',
    description2:
      '禁止存放和泄露，如有违反，责任由用户承担。',
    item_not_enough: '礼物不够',
    purchase_confirm: '你想购买吗？',
    purchase_success: '已购买故事项目。',
  },
};
